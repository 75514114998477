import { useEffect, useMemo } from 'react';
import { path } from 'ramda';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formatLocale } from 'utils/data';

import i18n from 'translate/i18n';

import { Field } from 'components/molecules';
import { Box, Row } from 'components/atoms';

import validators from 'validations/baseData';

const FormBaseData = () => {
  const {
    formState: { errors },
    getValues,
    register,
    setValue
  } = useFormContext();
  const { t } = useTranslation(['fields', 'validation']);

  const validations = useMemo(() => validators(t), [t]);

  const correspondingContractorName = useMemo(
    () => ({
      en: getValues('contractor_en'),
      fr: getValues('contractor_fr'),
      nl: getValues('contractor_nl')
    }),
    [getValues]
  );

  useEffect(() => {
    if (correspondingContractorName[formatLocale(i18n.language)] !== getValues('contractor'))
      setValue('contractor', correspondingContractorName[formatLocale(i18n.language)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, correspondingContractorName, getValues, setValue]);

  return (
    <Box display="flex" flexDirection="column" width={1}>
      <Row width={1} pb={[0, 0, 0, 4, 4]}>
        <Field
          {...register('contractor', validations?.contractor)}
          label={`${t('fields:contractor')}*`}
          valueInForm={getValues('contractor')}
          error={path(['contractor', 'message'], errors)}
          readOnly
          flex={1}
          mx={2}
        />
        <Field
          {...register('spoc', validations?.spoc)}
          label={`${t('fields:spoc')}*`}
          valueInForm={getValues('spoc')}
          error={path(['spoc', 'message'], errors)}
          readOnly
          flex={1}
          mx={2}
        />
        <Field
          {...register('spoc_email', validations?.spoc_email)}
          label={`${t('fields:spocEmail')}*`}
          valueInForm={getValues('spoc_email')}
          error={path(['spoc_email', 'message'], errors)}
          readOnly
          flex={1}
          mx={2}
        />
      </Row>
      <Row width={1} pb={[0, 0, 0, 4, 4]}>
        <Field
          {...register('employee_name', validations?.employee_name)}
          label={`${t('fields:firstname')}*`}
          valueInForm={getValues('employee_name')}
          error={path(['employee_name', 'message'], errors)}
          flex={1}
          mx={2}
        />
        <Field
          {...register('employee_surname', validations?.employee_surname)}
          label={`${t('fields:lastname')}*`}
          valueInForm={getValues('employee_surname')}
          error={path(['employee_surname', 'message'], errors)}
          flex={1}
          mx={2}
        />
        <Field
          {...register('employee_email', validations?.employee_email)}
          label={`${t('fields:email')}*`}
          valueInForm={getValues('employee_email')}
          error={path(['employee_email', 'message'], errors)}
          readOnly
          flex={1}
          mx={2}
        />
      </Row>
    </Box>
  );
};

export default FormBaseData;
