import { useCallback, useEffect, useMemo, useState } from 'react';
import { any, array, bool, func, object, oneOfType, string } from 'prop-types';
import { useId } from 'react-id-generator';
import { or } from 'ramda';

import { usePrevious } from 'hooks';

import { Box, Icon, Text } from 'components/atoms';
import { getRadiusProps, openFile } from 'components/helpers';

import uploadLogo from 'assets/cloud_upload.svg';
import Styled from './UploadPicture.styled';

const UploadPicture = ({
  name,
  accept,
  src,
  alt,
  id,
  value,
  onChange,
  onBlur,
  objectFit,
  objectPosition,
  aspectRatio,
  renderIcon,
  iconName,
  iconSize,
  disabled,
  readOnly,
  placeholderContent,
  uploadPictureError,
  valueInForm,
  ...props
}) => {
  const defaultId = useId(1, 'uploadpicture')[0];
  const finalId = or(id, defaultId);
  const prevSrc = usePrevious(src, true);
  const [error, setError] = useState(false);
  const [focus, setFocus] = useState(false);
  const [innerSrc, setInnerSrc] = useState(src);
  const [isLoading, setIsLoading] = useState(!!src);
  const [innerValue, setInnerValue] = useState(value);
  const [currentValue, setCurrentValue] = useState(innerValue);

  const hasPicture = useCallback(() => innerSrc && !error && !isLoading, [innerSrc, error, isLoading]);

  const change = useCallback(
    (changeVal) => {
      if (!!changeVal || (changeVal === undefined && !valueInForm)) onChange(changeVal);
      if (value === undefined) setInnerValue(changeVal);
    },
    [valueInForm, onChange, value]
  );

  const downloadFile = useCallback(
    (e, currentFile = currentValue) => {
      e.stopPropagation();
      e.preventDefault();
      openFile(() => currentFile);
    },
    [currentValue]
  );

  const clickableProps = useMemo(
    () => (hasPicture() ? { role: 'button', tabindex: 0, onClick: (e) => downloadFile(e), cursor: 'pointer' } : null),
    [downloadFile, hasPicture]
  );

  useEffect(() => {
    setInnerValue(value);
    if (value) setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    if (innerValue) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setInnerSrc(e.target.result);
      };
      reader.readAsDataURL(innerValue);
    }
  }, [innerValue]);

  useEffect(() => {
    if (src !== prevSrc) {
      setInnerSrc(src);
      setError(false);
      setIsLoading(true);
    }
  }, [src, prevSrc]);

  return (
    <>
      <Box display="flex" flexDirection="row" {...props}>
        <Styled.Clickable {...clickableProps} onClick={(e) => (hasPicture ? downloadFile(e) : null)}>
          <Styled.Picture
            {...getRadiusProps(props)}
            src={uploadPictureError ? null : innerSrc}
            focus={focus}
            objectFit={objectFit}
            objectPosition={objectPosition}
            aspectRatio={aspectRatio}
            iconSize={iconSize}
            iconName={iconName}
            renderIcon={renderIcon}
            alt={alt}
            hasPicture={hasPicture}
            onError={() => {
              setError(true);
              setIsLoading(false);
            }}
            onLoad={() => {
              setError(false);
              setIsLoading(false);
            }}
          />
        </Styled.Clickable>

        <Styled.UploadPicture
          {...getRadiusProps(props)}
          htmlFor={finalId}
          focus={focus}
          readOnly={readOnly}
          disabled={disabled}>
          <Styled.Input
            readOnly={readOnly}
            disabled={disabled}
            accept={accept}
            name={name}
            type="file"
            id={finalId}
            onChange={({ target }) => {
              change(target?.files?.[0]);
            }}
            onFocus={() => {
              setFocus(true);
            }}
            onBlur={(event) => {
              setFocus(false);
              onBlur(event);
            }}
          />
          <Styled.Placeholder {...getRadiusProps(props)} focus={focus} hasPicture={() => null}>
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="column" justifyContent="center" m="auto" mt={0}>
                <Text textAlign="center">{placeholderContent.title}</Text>
                <Box m="auto" pt={3} pb={2}>
                  <img src={uploadLogo} alt="Upload" />
                </Box>
                <Text textAlign="center" pb={2}>
                  {placeholderContent.listFormats}
                </Text>
                <Text textAlign="center" px={2}>
                  {placeholderContent.photoNameDisclaimer}
                </Text>
              </Box>
            </Box>
          </Styled.Placeholder>
        </Styled.UploadPicture>
      </Box>
      <Styled.Error>{typeof uploadPictureError === 'string' && uploadPictureError}</Styled.Error>
    </>
  );
};

UploadPicture.propTypes = {
  /** input name */
  name: string,
  /** img src */
  src: string,
  /** img alt */
  alt: string,
  /** input id, should be set for maximum compatibility */
  id: string,
  /** input accept */
  accept: string,
  /** icon name */
  iconName: string,
  /** icon size */
  iconSize: string,
  /** picture placeholder icon element */
  renderIcon: func,
  /** input value */
  value: any,
  /** on file change */
  onChange: func,
  /** on field blur */
  onBlur: func,
  /** disabled */
  disabled: bool,
  /** readOnly */
  readOnly: bool,
  /** styled-system object-fit prop */
  objectFit: oneOfType([string, array, object]),
  /** styled-system object-position prop */
  objectPosition: oneOfType([string, array, object]),
  /** styled-system aspect-ratio prop */
  aspectRatio: oneOfType([string, array, object]),
  /** placeholder content */
  placeholderContent: object,
  /** error */
  uploadPictureError: oneOfType([bool, string]),
  valueInForm: object
};

UploadPicture.defaultProps = {
  name: null,
  src: null,
  alt: null,
  id: null,
  accept: 'image/*',
  renderIcon: (props) => <Icon {...props} />,
  iconName: 'MdImage',
  iconSize: 'undefined',
  value: undefined,
  onChange: () => null,
  onBlur: () => null,
  disabled: false,
  readOnly: false,
  objectFit: 'cover',
  objectPosition: 'center',
  aspectRatio: null,
  placeholderContent: null,
  uploadPictureError: null,
  valueInForm: null
};

export default UploadPicture;
