import { bool } from 'prop-types';

import { Text } from 'components/atoms';

const Tab = ({ selected, ...props }) => (
  <Text
    cursor="pointer"
    fontSize="4"
    fontWeight="1"
    borderBottom="1"
    textDecoration="none"
    px="3"
    color={selected ? 'primary' : 'grays.0'}
    borderBottomColor={selected ? 'primary' : 'secondary'}
    {...props}
  />
);

Tab.propTypes = {
  // is tab selected
  selected: bool.isRequired
};

export default Tab;
