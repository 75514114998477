import * as service from './service';

const initialState = {
  token: '',
  baseData: {},
  completionFeedback: {}
};

const carrier = {
  state: {
    ...initialState
  },
  reducers: {
    checkedToken(state, payload) {
      return {
        ...state,
        token: payload.token,
        baseData: payload.baseData
      };
    },
    gotCompletionFeedback(state, payload) {
      return {
        ...state,
        completionFeedback: payload
      };
    }
  },
  effects: {
    async checkToken(token) {
      const result = await service.checkToken(token);
      if (result) this.checkedToken({ token, baseData: result });
      return result;
    },
    async sendCarrierData(data) {
      const result = await service.sendCarrierData(data);
      if (result) this.gotCompletionFeedback(result);
      return result;
    }
  }
};

export default carrier;
