/* eslint-disable camelcase */
import { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { omit } from 'ramda';
import { FormProvider, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { renderOnCondition } from 'utils/component';
import { formatLocale } from 'utils/data';

import i18n from 'translate/i18n';

import { Box, Button, Card, Heading, Text } from 'components/atoms';
import sendIcon from 'assets/send.svg';
import FormBaseData from './FormBaseData';
import FormNewData from './FormNewData';
import FormPhoto from './FormPhoto';
import FinalScreen from './FinalScreen';

const Form = ({ baseData, sendCarrierData, token, isPictureMandatory }) => {
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: { ...baseData, contractor_en: baseData.contractor, national_nr: '' }
  });
  const { handleSubmit } = formMethods;
  const { t } = useTranslation(['general', 'validation']);

  const [hasSentApplication, setHasSentApplication] = useState(false);

  const onSubmit = (data) => {
    setHasSentApplication(true);
    const tweakData = (currentData) => {
      const tweakedData = omit(['returnCode', 'contractor_nl', 'contractor_fr', 'isPicRequired'], {
        ...currentData,
        contractor: currentData.contractor_en,
        // We format national_nr as <Nationality (2 letters)>-<Actual national number>
        national_nr: `${currentData.nationality.code}-${currentData.national_nr}`,
        nationality: currentData.nationality.code,
        language: formatLocale(i18n.language) === 'nl' ? 'du' : formatLocale(i18n.language),
        token
      });
      return omit(['contractor_en'], tweakedData);
    };
    sendCarrierData(tweakData(data));
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={[1, 1, 1, 1, isPictureMandatory ? 'none' : 1]}
      width={[1, 1, 1, 1, isPictureMandatory ? 2 / 3 : 1]}>
      <FormProvider {...formMethods}>
        <Card
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          bg="light"
          display="flex"
          flexDirection="column"
          flex={1}
          borderRadius={[0, 0, 4, 4, 4]}
          border={3}
          boxShadow={1}
          p={4}
          mx={[0, 0, 0, 0, 4]}>
          {hasSentApplication ? (
            <FinalScreen />
          ) : (
            <>
              <Box display="flex" flexDirection="column" flex={1}>
                <Heading textAlign="center" fontSize={24} pt={8} pb={12}>
                  {t('general:form.title')}
                </Heading>
                <FormBaseData />
                <FormNewData />
                {renderOnCondition(isPictureMandatory, () => (
                  // Prop 'isPictureMandatory' not needed with renderOnCondition but kept for easy change of mind
                  <FormPhoto isPictureMandatory={isPictureMandatory} />
                ))}
              </Box>

              <Box display="flex" flexDirection="row" justifyContent="space-between" mx={2} mt={2}>
                <Text color="grays.4" fontSize={14}>
                  {`${t('validation:mandatory')}.`}
                </Text>
                <Button variant="primary" type="submit" borderRadius={2} py={3} px={5}>
                  <>
                    <img src={sendIcon} alt="Send" />
                    <Text color="light" fontSize={16} fontWeight={0} ml={2}>
                      {t('general:form.submit')}
                    </Text>
                  </>
                </Button>
              </Box>
            </>
          )}
        </Card>
      </FormProvider>
    </Box>
  );
};

Form.propTypes = {
  baseData: object,
  completionFeedback: object,
  sendCarrierData: func.isRequired,
  token: string.isRequired,
  isPictureMandatory: bool.isRequired
};

Form.defaultProps = {
  baseData: null,
  completionFeedback: null
};

const mapState = ({ carrier: { baseData, completionFeedback } }) => ({
  baseData,
  completionFeedback
});

const mapDispatch = ({ carrier: { sendCarrierData } }) => ({
  sendCarrierData
});

export default connect(mapState, mapDispatch)(Form);
