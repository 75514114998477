import { useTranslation } from 'react-i18next';

import { Translate } from 'components/organisms';
import { Box, Text } from 'components/atoms';

import logo from 'assets/logo.svg';

const TopBar = () => {
  const { t } = useTranslation('general');
  return (
    <Box display="flex" flexDirection="row" bg="darkBlue" py={4} px={[34, 34, 68, 68, 68]}>
      <Box display="flex" flex={1} justifyContent="flex-start" alignItems="flex-start">
        <Box display="flex" flexDirection="row">
          <img src={logo} alt="SNCB Logo" />
          <Text color="light" fontSize={16} fontWeight="800" my="auto" ml={4}>
            {t('general:title')}
          </Text>
        </Box>
      </Box>
      <Box
        display="flex"
        flex={[0, 0, 1, 1, 1]}
        justifyContent="flex-end"
        alignItems={['center', 'center', 'flex-end', 'flex-end', 'flex-end']}>
        <Translate />
      </Box>
    </Box>
  );
};

export default TopBar;
