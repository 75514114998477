import { forwardRef } from 'react';
import { omit, pick } from '@styled-system/props';
import { bool, func, node, object, oneOf, oneOfType, string } from 'prop-types';
import { useId } from 'react-id-generator';
import { or } from 'ramda';

import { Error, Text } from 'components/atoms';

import Styled from './CheckBox.styled';

const CheckBox = forwardRef(
  (
    {
      as,
      type,
      checked,
      label,
      renderLabel,
      onChange,
      onCheckedChange,
      disabled,
      readOnly,
      id,
      name,
      error,
      mainProps,
      ...props
    },
    ref
  ) => {
    const defaultId = useId(1, type)[0];
    const finalId = or(id, defaultId);
    return (
      <Styled.CheckBox as={as} {...pick(props)}>
        <Styled.Label {...mainProps} disabled={disabled} readOnly={readOnly} htmlFor={finalId} aria-hidden>
          <Styled.Input
            {...omit(props)}
            onClick={(event) => {
              if (readOnly) {
                event.stopPropagation();
                event.preventDefault();
              }
            }}
            type={type}
            name={name}
            id={finalId}
            disabled={disabled}
            ref={ref}
            checked={checked}
            onChange={(event) => {
              onChange(event);
              onCheckedChange(event?.target?.checked);
            }}
          />
          <Styled.MimicBox type={type} readOnly={readOnly} error={error}>
            {readOnly && <Styled.ClosedIcon name="MdClose" size={16} />}
            {(type === 'checkbox' || readOnly) && <Styled.CheckedIcon name="MdCheck" size={16} />}
            {type === 'radio' && !readOnly && <Styled.CircleIcon />}
          </Styled.MimicBox>
          {renderLabel({
            as: 'span',
            ml: label ? 2 : 0,
            children: label
          })}
        </Styled.Label>
        <Error>{typeof error === 'string' && error}</Error>
      </Styled.CheckBox>
    );
  }
);

CheckBox.propTypes = {
  /** input type */
  type: oneOf(['radio', 'checkbox']),
  /** as prop of container */
  as: oneOfType([string, object, func]),
  /** is checked */
  checked: bool,
  /** default is checked */
  defaultChecked: bool,
  /** main props, pass in any prop that you specifically want on the main element */
  mainProps: object,
  /** checkbox text */
  label: node,
  /** custom text element */
  renderLabel: func,
  /** native onChange, is passed the whole event */
  onChange: func,
  /** is only passed the value */
  onCheckedChange: func,
  /** is disabled */
  disabled: bool,
  /** is readonly */
  readOnly: bool,
  /** input id, should be set for maximum compatibility */
  id: string,
  /** input name */
  name: string,
  /** field error, only shows red border if true */
  error: oneOfType([bool, string])
};

CheckBox.defaultProps = {
  type: 'checkbox',
  as: 'div',
  checked: undefined,
  defaultChecked: undefined,
  mainProps: null,
  label: null,
  renderLabel: (props) => <Text {...props} />,
  onChange: () => null,
  onCheckedChange: () => null,
  disabled: false,
  readOnly: false,
  id: null,
  name: null,
  error: null
};

export default CheckBox;
