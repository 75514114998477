import { forwardRef } from 'react';
import { array, node, number, object, oneOfType, string } from 'prop-types';

import Text from '../Text/Text';

const Error = forwardRef(
  ({ mt, mb, ml, children, ...props }, ref) =>
    children && (
      <Text ref={ref} mt={mt} mb={mb} ml={ml} {...props}>
        {children}
      </Text>
    )
);

Error.propTypes = {
  color: oneOfType([string, array, object]),
  fontSize: oneOfType([number, string, array, object]),
  mt: oneOfType([number, string, array, object]),
  mb: oneOfType([number, string, array, object]),
  ml: oneOfType([number, string, array, object]),
  children: node
};

Error.defaultProps = {
  color: 'red',
  fontSize: 1,
  mt: 1,
  mb: -2,
  ml: 4,
  children: null
};

export default Error;
