import { is, isNil, join, map, mergeRight, pipe } from 'ramda';
import { toast } from 'react-toastify';
import { stringify } from 'qs';
import axios from 'axios';

import https from 'https-browserify';

import mockApi from 'mockApi';
import history from 'utils/history';
import i18n from 'translate/i18n';

/**
 * Custom error handler for project
 * @param  {Error} error            error received from API
 * @param  {any} [returnValue=null] value returned after function is executed
 * @return {any}
 */
export const handleError = (error, { returnValue = null, message } = {}) => {
  const { code } = error;
  let toastMessage = message;
  if (!toastMessage) {
    switch (code) {
      case 404:
        toastMessage = i18n.t([`errors:${error.message}`, 'errors:notFound']);
        break;
      case 401:
        toastMessage = i18n.t([`errors:${error.message}`, 'errors:unauthorized']);
        break;
      case 500:
      case 400:
        if (error.message) {
          toastMessage = i18n.t(`errors:${error.message}`);
        }
        break;
      default:
        toastMessage = i18n.t('errors:default');
        break;
    }
  }
  if (toastMessage) toast.error(toastMessage);
  return returnValue;
};

/**
 * Formats thr "order" param in search requests
 * @param  {string} defaultValue default order
 * @param  {Object} params       order received from Table component
 * @return {string}
 */
export const getOrderParams = (defaultValue, params) => {
  if (params?.length) {
    return pipe(
      map((param) => `${param.desc ? '-' : ''}${param.id}`),
      join(',')
    )(params);
  }
  return defaultValue;
};

/**
 * Secures API calls
 * @param  {function} functionToTry function that makes the API call
 * @param  {any} catchReturn   return value of catch. If function, it is executed, if not, default error handler is called and returns that value
 * @return {any} if sucess, returns the result of functionToTry
 */
export const secureCall =
  (functionToTry, catchReturn) =>
  async (...args) => {
    try {
      return await functionToTry(...args);
    } catch (e) {
      return is(Function, catchReturn) ? catchReturn(e, ...args) : handleError(e, catchReturn);
    }
  };

export const httpsAgent = new https.Agent({
  cert: process.env.REACT_APP_CERT,
  key: process.env.REACT_APP_KEY
});

const realApi = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Accept-language': i18n.language
  },
  httpsAgent,
  baseURL: process.env.REACT_APP_API_URL,
  paramsSerializer: (params) => stringify(params, { indices: false })
});

export const api = process.env.REACT_APP_MOCKS === 'true' ? mockApi(realApi) : realApi;

export const setupApi = (store) => {
  api.interceptors.response.use(
    (response) => (!isNil(response.data) ? response.data : response),
    (error) => {
      const code = error?.response?.status;

      if (code === 401) store.dispatch.user.logout();
      else if (code === 503) history.push('/error/503');
      return Promise.reject(error);
    }
  );
};

export const searchRequest = async (getData, criteria) => {
  const data = await getData(mergeRight({ limit: 20, count: true, updateStore: false }, criteria));
  return data || { rows: [], count: 0 };
};
