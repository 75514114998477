import { bool } from 'prop-types';

import { Item } from 'components/atoms';

const Option = ({ multiple, selected, disabled, ...props }) => (
  <Item
    justifyContent={multiple ? 'space-between' : 'flex-start'}
    color={selected ? 'primary' : 'dark'}
    highlightColor={selected ? 'primary' : 'dark'}
    opacity={disabled ? 0.5 : 1}
    {...props}
  />
);

Option.propTypes = {
  /** is selected */
  selected: bool.isRequired,
  /** is multiple */
  multiple: bool.isRequired,
  /** is disabled */
  disabled: bool.isRequired
};

export default Option;
