/* istanbul ignore file */
import { useEffect, useState } from 'react';

import usePrevious from 'hooks/usePrevious';

export default (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const prevValue = usePrevious(debouncedValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return prevValue !== debouncedValue ? debouncedValue : null;
};
