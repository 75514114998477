import { keys, values } from 'ramda';

const baseTheme = {
  breakpoints: { xs: '450px', sm: '750px', md: '1050px', lg: '1350px', xl: '1650px' },
  space: ['0px', '4px', '8px', '12px', '16px', '20px', '24px', '28px', '32px', '36px', '40px', '44px', '48px', '52px'],
  fontSizes: ['1rem', '1.2rem', '1.4rem', '1.6rem', '1.8rem', '2rem', '2.4rem', '3rem'],
  fontWeights: ['400', '600', '800'],
  font: 'Arial, sans-serif',
  lineHeight: '1.5',
  transition: '.2s linear',
  radii: ['0px', '2px', '4px', '6px', '8px', '10px', '12px'],
  elementSizes: ['28px', '36px'],
  headerHeight: '48px',
  colors: {
    primary: '#0069B4', // NMBS Blue, used for buttons, links, focused fields
    secondary: '#D7D7D7', // Color used for lines / disabled
    darkBlue: '#25336C', // Color used for top bar
    paleBlue: '#EFF4F9', // Color used for page background when displaying form
    paleYellow: '#FFF4E5', // Color used as background of the beware icon
    fulvous: '#C27710', // Color used for beware icon and the 'properties' line of text
    light: '#fff', // Color used for form and legal informations background
    grays: ['#EBEBEB', '#CFCFCF', '#7E7E7E', '#777777', '#707070', '#5E5E5E', '#868686'],
    dark: '#333333', // Color used for texts
    success: '#2B7C2B', // Color used for verificated fields
    error: '#BB0000', // Color used for wrong fields
    blanket: 'rgba(0, 0, 0, .3)'
  },
  borderWidths: ['0px', '2px'],
  borders: [
    'none',
    '2px solid #CFCFCF',
    '2px solid #333333',
    '1px solid #D7D7D7',
    '1px dashed #707070',
    '1px solid #D7D7D740'
  ],
  shadows: ['none', '0px 2px 4px #0000001A', '0px -1px 3px #0000001A;', '0px 2px 4px 0px #EDEDED']
};

export const createTheme = ({ breakpoints, ...params }) => {
  const breakpointsArray = values(breakpoints);
  const extendedTheme = {
    ...params,
    breakpoints: breakpointsArray,
    breakpointsKeys: keys(breakpoints),
    mimicLabel: `calc(${params.lineHeight}em + ${params.space[1]})`,
    mq: breakpointsArray.map((bp) => `@media (min-width: ${bp})`)
  };
  extendedTheme.breakpointsKeys.forEach((key) => {
    extendedTheme.breakpoints[key] = breakpoints[key];
  });
  return extendedTheme;
};

export default baseTheme;
