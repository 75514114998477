import { object } from 'prop-types';

import Styled from './Accessory.styled';

const DefaultAccessory = ({ defaultAccessoryProps, ...props }) => (
  <Styled.Accessory {...defaultAccessoryProps} {...props} />
);

DefaultAccessory.propTypes = {
  defaultAccessoryProps: object.isRequired
};

export default DefaultAccessory;
