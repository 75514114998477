export const shouldHide = (p) => p.readOnly && p.multiple && !p.textList;

export const getCursor = (openDD, p) => (!p.canSearch || !openDD) && !p.readOnly && { cursor: 'pointer' };

export const getInputProps = (openDD, p) => ({
  inputProps: {
    readOnly: !p.canSearch || p.readOnly || !openDD,
    ...getCursor(openDD, p),
    ...p.inputProps
  }
});

export const getAccessoryProps = (p) => ({
  ...(!p.readOnly && {
    endAccessoryProps: { cursor: 'pointer' },
    startAccessoryProps: { cursor: 'pointer' }
  })
});

export const showReset = (selection, p) => p.resetable && !!selection.length;

export const shouldClear = (isSearchable, isOpen, p) => {
  if (p.multiple) {
    return (!isOpen && !p.textList) || (isOpen && isSearchable);
  }
  return isSearchable && isOpen && !p.multiple;
};

export const buildTextList = (selection, getOptionLabel) =>
  selection.map((option) => getOptionLabel(option)).join(', ');

export const shouldTextList = (isSearchable, isOpen, p) =>
  (p.multiple && p.textList && !isSearchable) || (p.multiple && p.textList && !isOpen);

export const getPlaceholder = (defaultPlaceholder, readOnlyPlaceholder, selection, p) => {
  if (selection.length && p.canSearch) {
    if (!p.multiple) return p.getOptionLabel(selection[0]);
    if (p.textList) return buildTextList(selection, p.getOptionLabel);
  }
  return p.readOnly ? p.readOnlyPlaceholder ?? readOnlyPlaceholder : p.placeholder ?? defaultPlaceholder;
};

export const areOptionsLoading = (filteredOptions, p) => p.isLoading || filteredOptions.isLoading;

export const showTags = (p) => p.multiple && !p?.tagsProps?.hide;
