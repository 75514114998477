import { forwardRef } from 'react';
import { func, node, object, oneOfType, string } from 'prop-types';

import Styled from './Box.styled';

const Box = forwardRef((props, ref) => <Styled.Box ref={ref} {...props} />);

Box.propTypes = {
  /** element to render as */
  as: oneOfType([string, object, func]),
  children: node
};

Box.defaultProps = {
  as: 'div',
  children: null
};

export default Box;
