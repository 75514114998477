import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { color, compose, layout, space, system } from 'styled-system';
import { cover } from 'polished';

import { Card } from 'components/atoms';

const modalAnimation = keyframes`
  0% { opacity: 0; transform: scale(0.9); }
  100% { opacity: 1; }
`;

const bgAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export default {
  Modal: styled.div`
    ${(p) => css`
      ${cover()}
      position: fixed;
      background: ${p.theme.colors.blanket};
      padding: ${p.theme.space[2]};
      display: flex;
      justify-content: center;
      align-items: center;
      animation: ${bgAnimation} ${p.theme.transition};
    `}
    ${system({ zIndex: true })}
  `,
  Cover: styled.div`
    ${cover()}
  `,
  Content: styled(Card)`
    ${(p) => css`
      position: relative;
      z-index: 10;
      display: flex;
      flex-direction: column;
      animation: ${modalAnimation} ${p.theme.transition};
    `}
  `,
  Body: styled.div`
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    ${compose(space, color, layout)}
  `
};
