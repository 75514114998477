import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Box } from 'components/atoms';
import { func, number } from 'prop-types';
import { pathOr, propOr } from 'ramda';

export const AutoSizerDecoy = ({ children }) => <>{children({})}</>;

AutoSizerDecoy.propTypes = {
  children: func.isRequired
};

const Items = ({ rowRenderer, noRowsRenderer, rowCount }) => {
  if (!rowCount) return noRowsRenderer();
  return [...Array(rowCount)].map((_, index) => rowRenderer({ key: index, index, style: null }));
};

Items.propTypes = {
  rowRenderer: func.isRequired,
  noRowsRenderer: func.isRequired,
  rowCount: number.isRequired
};

export const ListDecoy = forwardRef(({ onRowsRendered, rowCount, height, threshold, ...props }, ref) => {
  const containerRef = useRef();

  useImperativeHandle(ref, () => ({
    scrollToRow(index) {
      const item = pathOr([], ['current', 'children'], containerRef)[index];
      if (item) item.scrollIntoView({ block: 'nearest' });
    },
    recomputeRowHeights() {
      return null;
    }
  }));

  return (
    <Box
      overflowY="auto"
      position="relative"
      maxHeight={height}
      onScroll={(e) => {
        const childrenList = pathOr([], ['current', 'children'], containerRef);
        const pxThreshold = propOr(0, 'offsetTop', childrenList[childrenList.length - threshold]);
        if (e.target.scrollTop + height >= pxThreshold) {
          onRowsRendered({ overscanStartIndex: 0, overscanStopIndex: rowCount, startIndex: 0, stopIndex: rowCount });
        }
      }}>
      <ul ref={containerRef}>
        <Items {...props} rowCount={rowCount} />
      </ul>
    </Box>
  );
});

ListDecoy.propTypes = {
  threshold: number.isRequired,
  onRowsRendered: func.isRequired,
  rowCount: number.isRequired,
  height: number.isRequired
};
