/* eslint-disable complexity */
/* eslint-disable max-lines */
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { array, bool, func, node, number, object, oneOf, oneOfType, string } from 'prop-types';
import { omit, pick } from '@styled-system/props';
import { useId } from 'react-id-generator';
import { or } from 'ramda';

import { renderOnCondition } from 'utils/component';

import { Box, Error, Text } from 'components/atoms';

import { displayReset, getType, hasAccessory, isDisabled, setNativeValue } from './Field.helpers';
import Accessory from './Accessory/Accessory';
import DefaultAccessory from './Accessory/DefaultAccessory';
import Styled from './Field.styled';

const Field = forwardRef((props, ref) => {
  const {
    type,
    name,
    label,
    precision,
    elementSize,
    mimicLabel,
    mainProps,
    inputProps,
    contentProps,
    disabled,
    readOnly,
    error,
    manualFocus,
    value,
    children,
    elementRef,
    onMouseDown,
    hideInput,
    onFocus,
    onBlur,
    onChange,
    onValueChange,
    onReset,
    id,
    mb,
    startIcon,
    startTextIcon,
    renderStartAccessory,
    startAccessoryProps,
    endIcon,
    endTextIcon,
    renderEndAccessory,
    endAccessoryProps,
    valueInForm,
    ...innerInputProps
  } = props;
  const defaultId = useId(1, 'field')[0];
  const finalId = or(id, defaultId);
  const [focus, setFocus] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const innerRef = useRef();
  const isFocused = manualFocus || focus;
  const baseAccessoryProps = useMemo(
    () => ({
      focus: isFocused,
      disabled,
      readOnly,
      elementSize
    }),
    [isFocused, disabled, readOnly, elementSize]
  );
  const baseAccessoryMethods = useMemo(
    () => ({
      onMouseDown: (event) => {
        event.preventDefault();
      },
      onBlur: () => {
        setFocus(false);
      },
      onFocus: () => {
        setFocus(true);
      }
    }),
    []
  );

  const handleReset = useCallback(
    () => setShowReset(value !== undefined ? value : !!innerRef?.current?.value),
    [value, innerRef]
  );

  const change = (event) => {
    const {
      target: { value: newValue }
    } = event;
    handleReset();
    onChange(event);
    onValueChange(newValue);
  };

  const reset = (event) => {
    event.preventDefault();
    setNativeValue(innerRef.current, '');
    innerRef.current.dispatchEvent(new window.Event('input', { bubbles: true }));
    onReset();
  };

  useImperativeHandle(ref, () => innerRef.current);

  useEffect(() => {
    handleReset();
  }, [innerRef, handleReset]);

  return (
    <Styled.Container mb={mb} {...pick(props)}>
      <Styled.Main {...mainProps} aria-hidden htmlFor={finalId} onMouseDown={onMouseDown} mimicLabel={mimicLabel}>
        {children}
        <Styled.Content
          {...contentProps}
          type={type}
          hideInput={hideInput}
          readOnly={readOnly}
          disabled={disabled}
          ref={elementRef}
          error={error}
          focus={isFocused}
          elementSize={elementSize}>
          {hasAccessory(props, 'start') && (
            <Accessory
              {...baseAccessoryProps}
              {...baseAccessoryMethods}
              renderElement={renderStartAccessory}
              icon={readOnly ? 'MdLock' : startIcon}
              color={readOnly ? 'grays.5' : 'inherit'}
              textIcon={startTextIcon}
              {...startAccessoryProps}
            />
          )}
          <Styled.Input
            readOnly={readOnly}
            color={readOnly ? 'grays.3' : 'inherit'}
            value={value}
            id={finalId}
            {...omit(innerInputProps)}
            {...getType(props)}
            {...inputProps}
            placeholder={label}
            elementSize={elementSize}
            disabled={isDisabled(props)}
            name={name}
            hasStartIcon={hasAccessory({ ...props }, 'start')}
            hasEndIcon={hasAccessory({ ...props }, 'end')}
            ref={innerRef}
            onChange={change}
            onBlur={(event) => {
              setFocus(false);
              onBlur(event);
            }}
            onFocus={(event) => {
              setFocus(true);
              onFocus(event);
            }}
            py={6}
          />
          <Styled.Placeholder
            hasValue={!!value || !!valueInForm}
            ofReadOnly={readOnly}
            ofFocus={focus}
            ofError={!!error}>
            <Box display="flex" flexDirection="row">
              <Text>{label}</Text>
              {renderOnCondition(!!precision, () => (
                <Text fontStyle="italic" color="grays.2" ml={2}>
                  {precision}
                </Text>
              ))}
            </Box>
          </Styled.Placeholder>
          {displayReset({ ...props, showReset }) && (
            <Accessory
              {...baseAccessoryProps}
              tabIndex="0"
              pullRight={hasAccessory(props, 'end')}
              cursor="pointer"
              hoverColor="primary"
              icon="MdClear"
              onMouseDown={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  reset(event);
                }
              }}
              onClick={reset}
            />
          )}
          {hasAccessory(props, 'end') && (
            <Accessory
              {...baseAccessoryProps}
              {...baseAccessoryMethods}
              renderElement={renderEndAccessory}
              icon={error ? 'MdOutlineErrorOutline' : endIcon}
              color={error ? 'error' : 'inherit'}
              hoverColor={error ? 'error' : 'inherit'}
              size={20}
              textIcon={endTextIcon}
              {...endAccessoryProps}
            />
          )}
        </Styled.Content>
      </Styled.Main>
      <Error>{typeof error === 'string' && error}</Error>
    </Styled.Container>
  );
});

Field.DefaultAccessory = DefaultAccessory;

Field.propTypes = {
  /** field type, can also be 'textarea' */
  type: oneOf([
    'date',
    'datetime-local',
    'email',
    'month',
    'number',
    'password',
    'search',
    'tel',
    'text',
    'textarea',
    'time',
    'url'
  ]),
  /** input value, if set the input will be controlled  */
  value: oneOfType([number, string]),
  /** input default value to be used when uncontrolled */
  defaultValue: oneOfType([number, string]),
  /** styled-system display prop  */
  display: oneOfType([string, array, object]),
  /** styled-system flex-direction prop  */
  flexDirection: oneOfType([string, array, object]),
  /** syled-system margin-bottom prop */
  mb: oneOfType([number, string, array, object]),
  /** input id, should be set for maximum compatibility */
  id: string,
  /** custom styled-system prop based on theme.elementSizes */
  elementSize: oneOfType([number, string, array, object]),
  /** main props, pass in any flexbox styled-system prop that you specifically want on the main element */
  mainProps: object,
  /** input props, pass in any layout/typography styled-system prop that you specifically want on the input */
  inputProps: object,
  /** content props, pass in any border, color styled-system prop that you specifically want on the content */
  contentProps: object,
  /** label above input box */
  label: node,
  /** label above input box */
  precision: node,
  /** is disabled */
  disabled: bool,
  /** is readonly */
  readOnly: bool,
  /** field error, only shows red border if true */
  error: oneOfType([bool, string]),
  /** force focus styles */
  manualFocus: bool,
  /** force show reset */
  manualReset: bool,
  /** ref of input box */
  elementRef: oneOfType([object, func]),
  /** hide input box */
  hideInput: bool,
  /** can be reset */
  resetable: bool,
  /** on reset */
  onReset: func,
  /** on input box mousedown */
  onMouseDown: func,
  /** on input focus */
  onFocus: func,
  /** on input blur */
  onBlur: func,
  /** native onChange, is passed the whole event */
  onChange: func,
  /** is only passed the value */
  onValueChange: func,
  /** input name */
  name: string,
  /** add space above input to mimic a label */
  mimicLabel: bool,
  /** icon name */
  startIcon: string,
  /** text icon */
  startTextIcon: string,
  /** custom element to replace start accessory */
  renderStartAccessory: func,
  /** start accessory props */
  startAccessoryProps: object,
  /** icon name */
  endIcon: string,
  /** text icon */
  endTextIcon: string,
  /** custom element to replace end accessory */
  renderEndAccessory: func,
  /** end accessory props */
  endAccessoryProps: object,
  /** children are displayed between the label and the input box */
  children: node,
  /** value in form */
  valueInForm: oneOfType([string, object])
};

Field.defaultProps = {
  value: undefined,
  defaultValue: undefined,
  display: 'flex',
  flexDirection: 'column',
  mb: 5,
  id: null,
  elementSize: 1,
  inputProps: null,
  contentProps: null,
  mainProps: null,
  label: null,
  precision: null,
  type: 'text',
  disabled: false,
  readOnly: false,
  error: null,
  manualFocus: null,
  manualReset: false,
  elementRef: null,
  hideInput: false,
  resetable: false,
  onReset: () => null,
  onMouseDown: () => null,
  onFocus: () => null,
  onBlur: () => null,
  onChange: () => null,
  onValueChange: () => null,
  name: null,
  mimicLabel: false,
  startIcon: null,
  startTextIcon: null,
  renderStartAccessory: null,
  startAccessoryProps: null,
  endIcon: null,
  endTextIcon: null,
  renderEndAccessory: null,
  endAccessoryProps: null,
  children: null,
  valueInForm: null
};

export default Field;
