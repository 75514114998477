import { useEffect, useRef, useState } from 'react';
import { parseIncompletePhoneNumber } from 'libphonenumber-js';
import { equals, pick } from 'ramda';
import { bool, func, object, string } from 'prop-types';

import { usePrevious } from 'hooks';

import { Box, Icon, Text } from 'components/atoms';
import { Field, Select } from 'components/molecules';

import {
  codes,
  getNewCountry,
  getOptionText,
  getSelectInputProps,
  getSelectValue,
  getValue,
  parse
} from './PhoneField.helpers';

const PhoneField = ({
  value,
  defaultValue,
  defaultCountry,
  onValueChange,
  onChange,
  selectProps,
  readOnly,
  ...restProps
}) => {
  const [innerValue, setInnerValue] = useState(
    parse({ number: getValue(value, defaultValue), country: defaultCountry })
  );
  const prevValue = usePrevious(value, true);
  const [isOpen, setIsOpen] = useState(false);
  const [manualFocus, setManualFocus] = useState(false);
  const ref = useRef();
  const finalSelectProps = {
    placeholder: '',
    readOnlyPlaceholder: '',
    ...selectProps
  };

  const change = (changeVal) => {
    const parsed = parse(changeVal);
    onChange(parsed);
    onValueChange(parsed.number);
    setInnerValue((val) => ({
      ...val,
      ...pick(['country', 'countryCallingCode'], parsed),
      ...(value === undefined && parsed)
    }));
  };

  useEffect(() => {
    if (!equals(value, prevValue)) {
      setInnerValue((val) => {
        const newValue = parse({ ...val, number: value });
        return {
          ...val,
          ...newValue
        };
      });
    }
  }, [value, prevValue]);

  return (
    <Field
      {...restProps}
      ref={ref}
      readOnly={readOnly}
      manualFocus={manualFocus}
      value={innerValue?.formatted}
      onValueChange={(inputValue) =>
        change({
          ...innerValue,
          ...getNewCountry(inputValue, codes),
          number: parseIncompletePhoneNumber(inputValue)
        })
      }
      renderStartAccessory={() => (
        <Select
          {...finalSelectProps}
          mb="0"
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          readOnly={readOnly}
          getOptionLabel={(opt) => opt?.country}
          inputProps={getSelectInputProps({ ...finalSelectProps?.inputProps, isOpen }, innerValue)}
          manualFocus={isOpen}
          mainProps={{ flex: 1, onClick: (e) => e.stopPropagation() }}
          renderStartAccessory={({ cursor }) => (
            <Box display="flex" alignItems="center" pl="2" opacity={isOpen ? 0.5 : 1} fontSize="4" cursor={cursor}>
              <Icon name={innerValue.country || 'MdSettingsPhone'} library={innerValue.country ? 'flags' : 'md'} />
            </Box>
          )}
          renderEndAccessory={({ icon, focus, minHeight, ...props }) => (
            <Box {...props} minHeight={minHeight} pr="1" mr="0" display="flex" alignItems="center">
              <Icon name={icon} size={20} color={focus ? 'primary' : null} pointerEvents="none" />
            </Box>
          )}
          onSearch={(inputText, options) =>
            options.filter((option) => getOptionText(option).toLowerCase().includes(inputText.toLowerCase()))
          }
          renderOption={({ option, ...props }) => (
            <Select.Option {...props} option={option}>
              <Text fontSize="4" mr="2" display="flex" alignItems="center">
                <Icon name={option?.country} library="flags" />
              </Text>{' '}
              {option?.country} +{option?.countryCallingCode}
            </Select.Option>
          )}
          optionsProps={{ virtualized: true }}
          searchable
          dropdownProps={{
            width: 120,
            ...finalSelectProps?.dropdownProps
          }}
          value={getSelectValue(innerValue)}
          onChange={({ country, countryCallingCode }) => {
            change({ country, countryCallingCode });
            ref.current.focus();
          }}
          getOptionSelected={(item, selected) => item?.country === selected?.country}
          options={codes}
          onFocus={() => setManualFocus(true)}
          onBlur={() => setManualFocus(false)}
          contentProps={{ border: 'none', bg: 'transparent' }}
        />
      )}
    />
  );
};

PhoneField.propTypes = {
  /** input value, if set the input will be controlled  */
  value: string,
  /** input default value to be used when uncontrolled */
  defaultValue: string,
  /** input default country (alpha-2 code) */
  defaultCountry: string,
  /** on change, is passed the full phone object */
  onChange: func,
  /** is only passed the value */
  onValueChange: func,
  /** country select props */
  selectProps: object,
  /** is readonly */
  readOnly: bool
};

PhoneField.defaultProps = {
  value: undefined,
  defaultValue: undefined,
  defaultCountry: undefined,
  onChange: () => null,
  onValueChange: () => null,
  selectProps: null,
  readOnly: false
};

export default PhoneField;
