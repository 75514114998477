import { ACCEPTED_IMAGE_FORMATS, MAX_IMAGE_SIZE, MULTIPLIER, REGEXES } from 'utils/constants';
import { splitFileName } from 'utils/data';

export default (t, getValues, isPictureMandatory) =>
  isPictureMandatory
    ? {
        photo: {
          required: isPictureMandatory ? `${t('validation:mandatory')}` : null,
          validate: () => {
            const file = getValues('photo');
            if (!ACCEPTED_IMAGE_FORMATS.ACCEPT_VALUES.includes(file?.type)) {
              return `${t('validation:photo.format')} ${ACCEPTED_IMAGE_FORMATS.FORMAT_NAMES.join(', ')}`;
            }
            if (file?.size > MAX_IMAGE_SIZE)
              return `${t('validation:photo.size')} (max ${MULTIPLIER.toString()
                .split('.')
                .join(t('validation:photo.decimal'))} ${t('validation:photo.unit')})`;
            const { nom } = splitFileName(file.name);
            return !!nom?.match(REGEXES.ONLY_ALPHANUMERICS) || `${t('validation:photo.name')}`;
          }
        }
      }
    : null;
