export const USER_ROLES = {
  ADMIN: 4,
  USER: 2
};

export const USER_GROUPS = {
  ADMIN: [USER_ROLES.ADMIN],
  USER: [USER_ROLES.USER, USER_ROLES.ADMIN]
};

export const REGEXES = {
  EMAIL:
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x20-\x5b\x5d-\x7f]|\\[\x20-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x20-\x5a\x53-\x7f]|\\[\x20-\x7f])+)\])$/i,
  NAME: /^[A-Za-zÀ-ÖØ-öø-ÿ-.' ]+$/,
  PHONE: /^[+]?[(]?[0-9]{1,4}[)]?[-\s./0-9]*[0-9]+$/,
  URL: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
  ONLY_NUMBERS: /^\d+$/,
  ONLY_ALPHANUMERICS: /^[a-zA-Z0-9]+$/
};

export const PASSWORD_MIN_LENGTH = 12;

export const ACCEPTED_IMAGE_FORMATS = {
  FORMAT_NAMES: ['.JPG', '.JPEG'],
  ACCEPT_VALUES: ['image/jpeg', 'image/jpg']
};

const MB_UNIT = 1048576;
export const MULTIPLIER = 3.8;
export const MAX_IMAGE_SIZE = MULTIPLIER * MB_UNIT;
