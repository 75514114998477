import { forwardRef } from 'react';
import { array, func, node, number, object, oneOfType, string } from 'prop-types';

import Text from '../Text/Text';

const Label = forwardRef((props, ref) => <Text ref={ref} {...props} />);

Label.propTypes = {
  /** element to render as */
  as: oneOfType([string, object, func]),
  /** styled-system color prop */
  color: oneOfType([string, array, object]),
  /** styled-system margin-bottom prop */
  mb: oneOfType([number, string, array, object]),
  children: node
};

Label.defaultProps = {
  as: 'p',
  color: 'dark',
  mb: 1,
  children: null
};

export default Label;
