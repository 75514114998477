import styled from '@emotion/styled';
import { compose, system } from 'styled-system';

import { boxProps, elementSize } from 'components/helpers';

export default {
  Item: styled.li`
    outline: none;
    &:hover,
    &:focus {
      ${compose(
        system({
          highlightColor: {
            scale: 'colors',
            property: 'color'
          }
        }),
        system({
          highlightBg: {
            scale: 'colors',
            property: 'backgroundColor'
          }
        })
      )}
    }
    ${(p) => p.onClick && 'cursor: pointer;'}
    ${(p) =>
      p.disabled &&
      `
      pointer-events: none;
      opacity: 0.5;
    `}
    ${elementSize(['minWidth'])}
    ${boxProps}
    ${(p) =>
      p.highlight &&
      system({
        highlightColor: {
          scale: 'colors',
          property: 'color'
        }
      })}
    ${(p) =>
      p.highlight &&
      system({
        highlightBg: {
          scale: 'colors',
          property: 'backgroundColor'
        }
      })}
  `
};
