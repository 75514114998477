export const formatLocale = (locale = '') => locale?.substring(0, 2);

// Sort array of country objects. Will happen AFTER formatting
export const sortCountryData = (countries, locale = 'en') =>
  countries.sort((curr, next) =>
    curr.name[formatLocale(locale)].localeCompare(next.name[formatLocale(locale)], formatLocale(locale))
  );

export const formatCountryData = (countries) => {
  // Simplify name and translations props for our use
  const formattedCountries = countries?.map((country) => ({
    code: country?.cca2?.toLowerCase(),
    name: {
      en: country?.name?.common,
      fr: country?.translations?.fra?.common,
      nl: country?.translations?.nld?.common
    }
  }));
  return formattedCountries;
};

export const splitFileName = (fullname) => {
  const split = fullname?.split('.');
  const extension = split?.pop();
  const nom = split?.join('.');
  return { extension, nom };
};
