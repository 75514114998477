import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { color, compose, flexbox, layout, space } from 'styled-system';

import { Icon } from 'components/atoms';

export default {
  Container: styled.div`
    ${compose(flexbox, layout, space)}
  `,
  Table: styled.table`
    ${(p) => `
      width: 100%;
      border-collapse: collapse;
      transition: opacity ${p.theme.transition};
    `}
  `,
  Row: styled.tr`
    ${(p) => `
      height: ${p.theme.elementSizes[1]};
      tbody &:nth-of-type(even) {
        background: ${p.theme.colors.light};
      }
    `}
    ${(p) =>
      p.noData
        ? css`
            tbody &:nth-of-type(odd) {
              background: linear-gradient(
                90deg,
                ${p.theme.colors.grays[3]} 20%,
                ${p.theme.colors.grays[2]},
                ${p.theme.colors.grays[3]} 80%
              );
              background-size: 200% 100%;
              animation: ${keyframes`
                0% { background-position: 150% 0% }
                50% { background-position: 50% 0% }
                100% { background-position: -50% 0% }
              `} 1s linear infinite;
            }
          `
        : `
        tbody &:nth-of-type(odd) {
          background: ${p.theme.colors.grays[3]};
        }
    `}
    ${(p) =>
      p.clickable &&
      `
      cursor: pointer;
      tbody &:hover {
        background: ${p.theme.colors.grays[2]};
      }
    `}
    ${(p) => p.isLoading && !p.noData && 'opacity: 0.5;'}
  `,
  Cell: styled.td`
    ${(p) => `
      text-align: left;
      font-weight: ${p.theme.fontWeights[0]};
      padding: ${p.theme.space[1]} ${p.theme.space[3]};
      height: ${p.theme.elementSizes[1]};
      color: ${p.theme.colors.dark};
    `}
    ${(p) => p.as === 'th' && 'white-space: nowrap;'}
    ${(p) =>
      p.sticky &&
      `
      position: sticky;
      top: 0;
    `}
    ${compose(color, layout, space)}
  `,
  Arrow: styled(Icon)`
    ${(p) => `margin-left: ${p.theme.space[1]};`}
    ${(p) => p.isSorted && `color: ${p.theme.colors.primary};`}
  `
};
