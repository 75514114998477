import { useMemo } from 'react';
import { path } from 'ramda';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import i18n from 'translate/i18n';
import { formatCountryData, formatLocale, sortCountryData } from 'utils/data';

import { Field, Select } from 'components/molecules';
import { Row } from 'components/atoms';

import validators from 'validations/newData';

import countryData from 'assets/files/countryData.json';

const FormNewData = () => {
  const {
    control,
    formState: { errors },
    getValues,
    register
  } = useFormContext();
  const { t } = useTranslation(['fields', 'validation']);

  const validations = useMemo(() => validators(t), [t]);
  const formattedCountryData = useMemo(() => formatCountryData(countryData), []);
  const sortedCountryData = useMemo(
    () => sortCountryData(formattedCountryData, i18n?.language),
    [formattedCountryData]
  );

  return (
    <Row width={[1, 1, 1, 1, 2 / 3]}>
      <Controller
        control={control}
        name="nationality"
        rules={validations?.nationality}
        render={({ field: { onChange, value } }) => (
          <Select
            label={`${t('fields:nationality.label')}*`}
            precision={`(${t('fields:nationality.infoFilter')})`}
            options={sortedCountryData}
            onChange={onChange}
            value={value}
            valueInForm={getValues('nationality')}
            getOptionSelected={(option, val) => option?.code === val?.code}
            getOptionLabel={(option) => option?.name[formatLocale(i18n.language)]}
            noResultMessage={`${t('fields:nationality.noResult')}`}
            resetable
            searchable
            error={path(['nationality', 'message'], errors)}
            flex={1}
            mx={2}
          />
        )}
      />
      <Field
        {...register('national_nr', validations?.national_nr)}
        label={`${t('fields:nationalNumber')}*`}
        valueInForm={getValues('national_nr')}
        error={path(['national_nr', 'message'], errors)}
        flex={1}
        mx={2}
      />
    </Row>
  );
};

export default FormNewData;
