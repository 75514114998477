/* eslint-disable complexity */
/* eslint-disable max-lines, max-statements */
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { any, array, bool, func, node, number, object, oneOfType, shape, string } from 'prop-types';
import { useId } from 'react-id-generator';
import { equals, isNil, or, pluck } from 'ramda';

import { usePrevious } from 'hooks';

import { isVisible, transformValue } from 'components/helpers';
import { Tag } from 'components/atoms';

import {
  areOptionsLoading,
  buildTextList,
  getAccessoryProps,
  getCursor,
  getInputProps,
  getPlaceholder,
  shouldClear,
  shouldHide,
  shouldTextList,
  showReset,
  showTags
} from './Select.helpers';
import Field from '../Field/Field';
import Tags from '../Tags/Tags';
import Dropdown from '../Dropdown/Dropdown';
import Options from './Options/Options';
import Option from './Option/Option';

const Select = forwardRef((props, ref) => {
  const {
    id,
    textList,
    getOptionLabel,
    getOptionSelected,
    getOptionDisabled,
    searchable,
    creatable,
    onSearch,
    options,
    value,
    defaultValue,
    onCreateOption,
    getNewOptionData,
    onChange,
    onOpen,
    onClose,
    multiple,
    disabled,
    readOnly,
    onReset,
    isLoading,
    renderElement,
    renderTag,
    renderOption,
    renderNoOption,
    renderNewOption,
    noResultMessage,
    optionsProps,
    tagsProps,
    onTagsChange,
    dropdownProps,
    pageStart,
    cacheOptions,
    loadOptions,
    threshold,
    precision,
    ...restProps
  } = props;
  const defaultId = useId(1, 'select')[0];
  const finalId = or(id, defaultId);
  const canSearch = useMemo(() => creatable || searchable, [creatable, searchable]);
  const [openDD, setOpenDD] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selection, setSelection] = useState(
    transformValue('in', multiple, value !== undefined ? value : defaultValue)
  );
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState({
    count: 0,
    isLoading: false,
    options,
    hasCreateOption: false
  });
  const defaultOptions = useRef(null);
  const activeLoadMore = useRef(true);
  const currentPage = useRef(pageStart);
  const closeCounter = useRef(0);
  const debounce = useRef();
  const filterVal = useRef('');
  const isMounted = useRef(false);
  const prevOptions = usePrevious(options, true);
  const prevValue = usePrevious(value, true);
  const prevOpenDD = usePrevious(openDD, true);
  const elementRef = useRef(null);
  const focusRef = useRef(null);
  const optionsRef = useRef(null);
  const canClose = useRef(null);

  const updateOptions = useCallback(
    (getNewOptions, filter) => {
      setFilteredOptions((opts) => {
        let finalOptions = { ...getNewOptions(opts), hasCreateOption: false };
        if (
          creatable &&
          filter &&
          !finalOptions.isLoading &&
          !finalOptions.options.find((opt) => getOptionLabel(opt) === getOptionLabel(getNewOptionData(filter)))
        ) {
          finalOptions = {
            ...finalOptions,
            options: [...finalOptions.options, getNewOptionData(filter)],
            hasCreateOption: true
          };
        }
        return finalOptions;
      });
    },
    [creatable, getNewOptionData, getOptionLabel]
  );

  const search = useCallback(
    async (filter, page, counter) => {
      activeLoadMore.current = false;
      const res = await (page === pageStart && filter === '' && cacheOptions
        ? defaultOptions.current
        : loadOptions({ filter, page }));
      if (isMounted.current) {
        const stack = page > pageStart;
        activeLoadMore.current = true;
        if (filter === filterVal.current && (closeCounter.current === counter || !stack)) {
          updateOptions(
            (opts) => ({
              count: res.count,
              isLoading: false,
              options: stack ? [...opts.options, ...res.rows] : res.rows
            }),
            filter
          );
        }
      }
    },
    [closeCounter, filterVal, loadOptions, pageStart, updateOptions, cacheOptions]
  );

  const fetch = useCallback(
    (val) => {
      currentPage.current = pageStart;
      search(val, pageStart, closeCounter.current);
    },
    [search, pageStart]
  );

  const debouncedSearch = useCallback(
    (val, timeout = 500) => {
      clearTimeout(debounce.current);
      activeLoadMore.current = false;
      updateOptions(() => ({ count: 0, isLoading: true, options: [] }));
      filterVal.current = val;
      if (timeout) {
        debounce.current = setTimeout(() => {
          fetch(val);
        }, timeout);
      } else {
        fetch(val);
      }
    },
    [fetch, updateOptions]
  );

  const resetInput = useCallback(
    (isSearchable, isOpen) => {
      if (shouldClear(isSearchable, isOpen, { multiple, textList })) {
        setInputValue('');
      }
      if (!multiple && !isOpen) setInputValue(getOptionLabel(selection[0]) || '');
      if (shouldTextList(isSearchable, isOpen, { multiple, textList }))
        setInputValue(buildTextList(selection, getOptionLabel));
    },
    [getOptionLabel, multiple, selection, textList]
  );

  const change = (changeVal) => {
    onChange(transformValue('out', multiple, changeVal));
    if (value === undefined) setSelection(changeVal);
  };

  const closeFunc = (val) => {
    closeCounter.current += 1;
    canClose.current = false;
    setOpenDD(false);
    onClose(val);
    setSearchValue('');
  };

  const openFunc = () => {
    setOpenDD(true);
    onOpen();
    (focusRef.current || elementRef.current)?.focus?.();
    if (canSearch && !loadOptions) updateOptions((opts) => ({ ...opts, options }));
    if (loadOptions) debouncedSearch('', null);
  };

  const resetFunc = () => {
    change([]);
    if (loadOptions) {
      debouncedSearch('', null);
    }
    onReset();
  };

  const attachmentProps = {
    ...getCursor(openDD, { ...props, canSearch }),
    ...(!focusRef.current && { tabIndex: 0 }),
    onKeyDown: (e) => {
      if (openDD) {
        optionsRef.current.handleNavigation(e);
        if (e.key === 'Escape') {
          e.preventDefault();
          e.stopPropagation();
          closeFunc(transformValue('out', multiple, selection));
        }
      }
      if (e.key === 'Enter' && !openDD) {
        e.preventDefault();
        if (!disabled && !readOnly) openFunc();
      }
    },
    onKeyUp: (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (openDD) canClose.current = true;
    },
    onMouseDown: (e) => {
      if (!disabled && !readOnly && !openDD && e?.nativeEvent.which === 1) {
        openFunc();
        setTimeout(() => {
          canClose.current = true;
        }, 0);
      }
    }
  };

  const inputElementProps = {
    type: 'search',
    autoComplete: 'off',
    autoCorrect: 'off',
    spellCheck: false,
    placeholder: getPlaceholder('', '', selection, { ...props, canSearch }),
    value: inputValue,
    disabled,
    readOnly,
    onChange: ({ target: { value: changed } }) => {
      setInputValue(changed);
      setSearchValue(changed);
      if (loadOptions) {
        debouncedSearch(changed);
        return;
      }
      updateOptions((opts) => ({ ...opts, options: onSearch(changed, options, getOptionLabel) }), changed);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    if (cacheOptions && loadOptions) {
      const getDefaultOptions = () => {
        defaultOptions.current = loadOptions({ page: pageStart, filter: '' });
      };
      getDefaultOptions();
    }
    return () => {
      isMounted.current = false;
    };
  }, [isMounted, cacheOptions, loadOptions, pageStart]);

  useEffect(() => () => clearTimeout(debounce.current), [debouncedSearch]);

  useEffect(() => {
    if (!equals(options, prevOptions)) {
      updateOptions((opts) => ({ ...opts, options }));
    }
  }, [options, prevOptions, updateOptions]);

  useEffect(() => {
    // set new selection when options or value change
    // use deep check on value to avoid updating every time
    if (!equals(value, prevValue)) {
      setSelection(transformValue('in', multiple, value));
    }
  }, [multiple, value, prevValue]);

  useEffect(() => {
    if (!openDD || !canSearch || openDD !== prevOpenDD) {
      resetInput(canSearch, openDD);
    }
  }, [canSearch, openDD, resetInput, prevOpenDD]);

  useImperativeHandle(ref, () => ({
    search(filter) {
      debouncedSearch(filter, null);
    },
    reset() {
      resetFunc();
    }
  }));

  return (
    <>
      {renderElement({
        endIcon: openDD ? 'MdKeyboardArrowUp' : 'MdKeyboardArrowDown',
        ...restProps,
        precision: inputValue || restProps?.valueInForm ? null : precision,
        id: finalId,
        isOpen: openDD,
        manualReset: showReset(selection, props),
        manualFocus: openDD,
        hideInput: shouldHide(props),
        ...getInputProps(openDD, { ...props, canSearch }),
        ...getAccessoryProps(props),
        ref: focusRef,
        ...attachmentProps,
        ...inputElementProps,
        elementRef,
        // Spread inputProps on the input you want the dropdown to be attached to
        inputElementProps,
        // Spread attachmentProps on the element you want the dropdown to be attached to
        attachmentProps: { ...attachmentProps, ref: elementRef },
        onReset: resetFunc,
        children: showTags(props) && (
          <Tags
            {...tagsProps}
            id={finalId}
            getTagLabel={getOptionLabel}
            renderTag={renderTag}
            value={selection}
            isLoading={isLoading}
            readOnly={readOnly || disabled}
            onChange={(tags) => {
              if (onTagsChange) return onTagsChange(tags);
              return change(tags.value);
            }}
          />
        )
      })}
      {openDD && (
        <Dropdown
          {...dropdownProps}
          focusTrapActive={false}
          attachEl={elementRef}
          update={{ selection, options: filteredOptions.options }}
          onRender={() => {
            if (!isVisible(elementRef.current) && openDD) {
              closeFunc(transformValue('out', multiple, filteredOptions));
            }
          }}
          onClickOutside={({ target }) => {
            if ((!canSearch || !pluck('current', [focusRef, elementRef]).includes(target)) && canClose.current) {
              closeFunc(transformValue('out', multiple, selection));
            }
          }}>
          <Options
            {...optionsProps}
            id={finalId}
            onCreateOption={onCreateOption}
            ref={optionsRef}
            getNewOptionData={getNewOptionData}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            getOptionDisabled={getOptionDisabled}
            renderOption={renderOption}
            renderNoOption={renderNoOption}
            renderNewOption={renderNewOption}
            noResultMessage={noResultMessage}
            pageStart={pageStart}
            resetInput={resetInput}
            searchValue={searchValue}
            onSearch={onSearch}
            count={filteredOptions.count}
            options={filteredOptions.options}
            hasCreateOption={filteredOptions.hasCreateOption}
            multiple={multiple}
            isLoading={areOptionsLoading(filteredOptions, props)}
            value={selection}
            attachEl={elementRef}
            threshold={threshold}
            onChange={(val) => {
              if (!isNil(val)) change(val);
              if (!multiple) {
                closeFunc(transformValue('out', multiple, val));
              }
            }}
            {...(activeLoadMore.current && {
              loadMore: () => {
                currentPage.current += 1;
                updateOptions((opts) => ({ ...opts, isLoading: true }));
                search(filterVal.current, currentPage.current, closeCounter.current);
              }
            })}
          />
        </Dropdown>
      )}
    </>
  );
});

Select.Option = Option;
Select.Options = Options;

Select.propTypes = {
  /** id  of the input, will also be used by tags and options (id_option-index and id_tag_delete-index) */
  id: string,
  /** select placeholder */
  placeholder: string,
  /** select placeholder when readonly and empty */
  readOnlyPlaceholder: string,
  /** used to determine the string value for a given option. It's used to fill the input (and the options list if renderOption is not provided).  */
  getOptionLabel: func,
  /** used to determine if an option is selected */
  getOptionSelected: func,
  /** used to determine if an option is disabled */
  getOptionDisabled: func,
  /** get created option data */
  getNewOptionData: func,
  /** can filter list */
  searchable: bool,
  /** can select custom element based on search */
  creatable: bool,
  /** array of options */
  options: array,
  /** selected option or array of options if multiple, use if controlled */
  value: oneOfType([any, array]),
  /** default selected option/options, use if uncontrolled */
  defaultValue: oneOfType([any, array]),
  /** can select multiple values */
  multiple: bool,
  /** is readonly */
  readOnly: bool,
  /** is disabled */
  disabled: bool,
  /** can be reset (no effect if multiple) */
  resetable: bool,
  /** is loading */
  isLoading: bool,
  /** element that the dropdown is attached to */
  renderElement: func,
  /** custom Option element */
  renderOption: func,
  /** custom no option element */
  renderNoOption: func,
  /** custom new option element */
  renderNewOption: func,
  /** Tag element */
  renderTag: func,
  /** Options no result message */
  noResultMessage: string,
  /** tags props, see Tags component */
  tagsProps: shape({
    noTagMessage: string,
    elementSize: oneOfType([number, string, array, object]),
    gutter: number,
    getTagLabel: func
  }),
  /** on tags change, defaults to simply updating the options */
  onTagsChange: func,
  /** dropdown props, see Dropdown component for styled-system props you can pass */
  dropdownProps: shape({
    automaticPosition: bool
  }),
  /** options props */
  optionsProps: shape({
    hideSelected: bool,
    elementSize: oneOfType([number, string, array, object]),
    visibleCount: number,
    virtualized: bool
  }),
  /** write comma separated labels in the input if multiple */
  textList: bool,
  /** replace base search function, return filtered options */
  onSearch: func,
  /** on creation option click, will not call onChange if defined (params: value, multiple, option) */
  onCreateOption: func,
  /** on selection change */
  onChange: func,
  /** on reset */
  onReset: func,
  /** on dropdown open */
  onOpen: func,
  /** on dropdown close */
  onClose: func,
  /** page start number */
  pageStart: number,
  /** asynchronous options load with pagination and infinite-scroll, function that takes (currentPage, searchValue) and must return a promise that resolves into { count: totalNumberOfOptions, rows: arrayOfOptions }, must be memoized */
  loadOptions: func,
  /** the index of the option (starting from the bottom of the list) that needs to be reached before the next call to loadOptions, this should always be lower than your limit param */
  threshold: number,
  /** if true, loadOptions will be fired on Select mount and the result will be cached and used every time the options need to reset to their default state instead of calling loadOptions again */
  cacheOptions: bool,
  precision: node
};

Select.defaultProps = {
  id: null,
  placeholder: null,
  readOnlyPlaceholder: null,
  getOptionLabel: (option) => option?.label,
  getOptionSelected: (option, value) => equals(option, value),
  getOptionDisabled: () => false,
  getNewOptionData: (label) => ({ label }),
  searchable: null,
  creatable: null,
  options: [],
  value: undefined,
  defaultValue: undefined,
  multiple: false,
  readOnly: false,
  disabled: false,
  resetable: false,
  isLoading: null,
  renderElement: (props) => <Field {...props} />,
  renderOption: (props) => <Option {...props} />,
  renderNoOption: (props) => <Option {...props} />,
  renderNewOption: (props) => <Option {...props} />,
  noResultMessage: null,
  renderTag: (props) => <Tag {...props} />,
  tagsProps: null,
  onTagsChange: null,
  dropdownProps: null,
  optionsProps: null,
  textList: false,
  onSearch: (inputText, options, getOptionLabel) =>
    options.filter((option) => getOptionLabel(option).toLowerCase().includes(inputText.toLowerCase())),
  onCreateOption: null,
  onChange: () => null,
  onReset: () => null,
  onOpen: () => null,
  onClose: () => null,
  pageStart: 1,
  loadOptions: null,
  threshold: 10,
  cacheOptions: false,
  precision: null
};

export default Select;
