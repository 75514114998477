/* eslint-disable camelcase */
import { REGEXES } from 'utils/constants';

export default (t) => ({
  nationality: {
    required: `${t('validation:mandatory')}`
  },
  national_nr: {
    required: `${t('validation:mandatory')}`,
    pattern: {
      value: REGEXES.ONLY_ALPHANUMERICS,
      message: `${t('validation:onlyAlphanumerics')}`
    }
  }
});
