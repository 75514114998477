import { createPortal } from 'react-dom';
import { array, arrayOf, bool, func, node, number, object, oneOfType, string } from 'prop-types';
import TrapFocus from 'focus-trap-react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Heading, Row } from 'components/atoms';

import Styled from './Modal.styled';

const Modal = ({
  show,
  onClose,
  actions,
  children,
  title,
  zIndex,
  bodyProps,
  actionsBarProps,
  renderActionsBar,
  ...props
}) => {
  const { t } = useTranslation();

  if (!show) return null;

  return (
    <>
      {createPortal(
        <Styled.Modal role="dialog" zIndex={zIndex}>
          <Styled.Cover aria-hidden onMouseDown={onClose} />
          <TrapFocus focusTrapOptions={{ clickOutsideDeactivates: true }}>
            <Styled.Content
              {...props}
              p="0"
              aria-hidden
              tabIndex="-1"
              onKeyUp={(event) => {
                event.stopPropagation();
                if (event.key === 'Escape') onClose();
              }}>
              <Styled.Body p="5" {...bodyProps}>
                {title && (
                  <Heading as="h2" mb="4">
                    {title}
                  </Heading>
                )}
                {children}
              </Styled.Body>
              {(!actions || !!actions.length) && (
                <Box as="footer" borderTop="1">
                  {renderActionsBar({
                    gutter: 1,
                    justifyContent: 'flex-end',
                    pb: [3, 5],
                    px: 5,
                    pt: 5,
                    ...actionsBarProps,
                    children: actions ? (
                      actions.map((renderAction) =>
                        renderAction({
                          // onClick = onClose and cancel text so we just have to spread props on a button to make it close the modal
                          variant: 'secondary',
                          width: [1, 'auto'],
                          onClick: onClose,
                          children: t('cancel'),
                          mx: 1,
                          mb: [2, 0],
                          key: renderAction
                        })
                      )
                    ) : (
                      <Button
                        id="modal_close"
                        variant="secondary"
                        width={[1, 'auto']}
                        onClick={onClose}
                        mx="1"
                        mb={[2, 0]}>
                        {t('close')}
                      </Button>
                    )
                  })}
                </Box>
              )}
            </Styled.Content>
          </TrapFocus>
        </Styled.Modal>,
        document.body
      )}
    </>
  );
};

Modal.propTypes = {
  /** show modal */
  show: bool,
  /** styled system z-index prop */
  zIndex: oneOfType([number, string, array, object]),
  /** on modal close */
  onClose: func,
  /** modal title */
  title: node,
  /** styled-system maxWidth */
  maxWidth: oneOfType([number, string, array, object]),
  /** styled-system width */
  width: oneOfType([number, string, array, object]),
  /** styled-system maxHeight */
  maxHeight: oneOfType([number, string, array, object]),
  /** styled system space and color props of modal body */
  bodyProps: object,
  /** array of actions */
  actions: arrayOf(func),
  /** actions row props */
  actionsBarProps: object,
  /** render full modal actions bar */
  renderActionsBar: func,
  children: node
};

Modal.defaultProps = {
  show: false,
  zIndex: 9999,
  onClose: () => null,
  title: null,
  maxWidth: 734,
  width: '100%',
  maxHeight: '100%',
  bodyProps: null,
  actions: null,
  children: null,
  actionsBarProps: null,
  renderActionsBar: (props) => <Row {...props} />
};

export default Modal;
