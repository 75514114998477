import { api, secureCall } from 'utils/api';

export const checkToken = secureCall(
  async (token) => api.post('token', { token }),
  (e) => e
);

export const sendCarrierData = secureCall(
  async (data) => api.post('createcarrier', data, { headers: { 'Content-Type': 'multipart/form-data' } }),
  (e) => e
);
