import styled from '@emotion/styled';

import { boxProps, ellipsis } from 'components/helpers';

export default {
  Text: styled.p`
    ${ellipsis}
    ${boxProps}
  `
};
