import { useEffect, useMemo, useState } from 'react';
import { bool, object } from 'prop-types';
import { pathOr } from 'ramda';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';

import { Box, Picture, Text } from 'components/atoms';
import spinner from 'assets/lotties/spinner.json';
import thumbsUp from 'assets/thumbs_up.svg';
import Styled from './FinalScreen.styled';

const FinalScreen = ({ isSendingCarrierData, completionFeedback }) => {
  const [firstCycle, setFirstCycle] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation('general');

  const isSendingCarrierDataCheck = useMemo(
    () => isSendingCarrierData || firstCycle,
    [firstCycle, isSendingCarrierData]
  );

  useEffect(() => {
    const { returnCode, response } = completionFeedback;
    if (!returnCode && !response?.status && isSendingCarrierDataCheck) {
      setFirstCycle(false);
      return;
    }
    const status = returnCode || response?.status || 'default';
    if (status !== 200) navigate(`error/${status}`);
  }, [completionFeedback, isSendingCarrierDataCheck, navigate]);

  return (
    <Box display="flex" flexDirection="column" flex={1} justifyContent="center">
      <Box display="flex" flexDirection="column" position="relative">
        <Styled.FadeOut shouldTransition={!isSendingCarrierDataCheck}>
          <Lottie animationData={spinner} loop autoplay />
        </Styled.FadeOut>
        <Styled.FadeIn shouldTransition={!isSendingCarrierDataCheck}>
          <Picture src={thumbsUp} alt="All done !" m="auto" />
        </Styled.FadeIn>
      </Box>
      {isSendingCarrierDataCheck ? (
        <Box>
          <Text fontSize={14} textAlign="center">
            {t('general:finalScreen.submitting.0')}
          </Text>
          <Text fontSize={14} textAlign="center">
            {t('general:finalScreen.submitting.1')}
          </Text>
        </Box>
      ) : (
        <Box>
          <Text fontSize={16} fontWeight={2} textAlign="center">
            {t('general:finalScreen.success.0')}
          </Text>
          <Text fontSize={16} fontWeight={2} textAlign="center">
            {t('general:finalScreen.success.1')}
          </Text>
        </Box>
      )}
    </Box>
  );
};

FinalScreen.propTypes = {
  completionFeedback: object,
  isSendingCarrierData: bool.isRequired
};

FinalScreen.defaultProps = {
  completionFeedback: null
};

const mapState = ({ carrier: { baseData, completionFeedback }, loading }) => ({
  baseData,
  completionFeedback,
  isSendingCarrierData: pathOr(false, ['effects', 'carrier', 'sendCarrierData'], loading)
});

export default connect(mapState, null)(FinalScreen);
