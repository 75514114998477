/* eslint-disable max-lines */
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTheme } from '@emotion/react';
import { any, array, bool, func, number, object, oneOfType, string } from 'prop-types';
import { and, equals, isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';

import { Icon, NoResult, Text } from 'components/atoms';

import { AutoSizerDecoy, ListDecoy } from './react-virtualized-decoy';
import { getIncrementor, getNewValue, getVisibleCount, isSelected, shouldPreventSelection } from './Options.helpers';

const Options = forwardRef(
  (
    {
      id,
      onCreateOption,
      hasCreateOption,
      getNewOptionData,
      renderOption,
      renderNoOption,
      renderNewOption,
      noResultMessage,
      getOptionSelected,
      getOptionDisabled,
      getOptionLabel,
      hideSelected,
      elementSize,
      options,
      value,
      onChange,
      visibleCount,
      multiple,
      isLoading,
      searchValue,
      virtualized,
      count,
      loadMore,
      threshold
    },
    ref
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const listRef = useRef();
    const scrollRef = useRef();
    const finalOptions = useMemo(() => {
      let opts = options.filter(
        (option) =>
          !isSelected(option, value, getOptionSelected) ||
          (!hideSelected && !(getOptionSelected(getNewOptionData(searchValue), option) && hasCreateOption))
      );
      if (isLoading && !!opts.length) opts = [...opts, { loader: true }];
      return opts;
    }, [options, value, searchValue, hasCreateOption, hideSelected, getNewOptionData, getOptionSelected, isLoading]);
    const [highlight, setHighlight] = useState();
    const AutoSizerComponent = virtualized ? AutoSizer : AutoSizerDecoy;
    const ListComponent = virtualized ? List : ListDecoy;

    const getNextHighlight = useCallback(
      (index, incrementor, stack = 0) => {
        if (stack >= finalOptions.length) {
          return { option: finalOptions[0], index: 0 };
        }
        const nextRef = finalOptions[index + incrementor];
        if (!isNil(nextRef)) {
          listRef.current.scrollToRow(index + incrementor);
          return { option: finalOptions[index + incrementor], index: index + incrementor };
        }
        if (incrementor > 0 && index >= finalOptions.length) {
          listRef.current.scrollToRow(0);
          return getNextHighlight(-1, 1, stack);
        }
        if (incrementor < 0 && index <= 0) {
          listRef.current.scrollToRow(finalOptions.length - 1);
          return getNextHighlight(finalOptions.length, -1);
        }
        return getNextHighlight(index + incrementor, incrementor, stack + 1);
      },
      [finalOptions]
    );

    const onHover = useCallback(
      (option, index) => {
        const newHighlight = { option, index };
        if (!equals(newHighlight, highlight)) {
          setHighlight(newHighlight);
        }
      },
      [highlight]
    );

    const onSelect = useCallback(
      async (event, option) => {
        event.preventDefault();
        onChange(getNewValue(option, value, multiple, getOptionSelected));
      },
      [onChange, value, multiple, getOptionSelected]
    );

    const create = useCallback(
      (event, option) => {
        event.preventDefault();
        if (onCreateOption) {
          return onCreateOption(value, multiple, option);
        }
        return onChange(multiple ? [...value, option] : [option]);
      },
      [onChange, value, multiple, onCreateOption]
    );

    const noRowsRenderer = useCallback(
      (noRowsProps) => {
        const index = noRowsProps?.index;
        const text = noResultMessage || t('noResult');
        return renderNoOption({
          as: virtualized ? 'div' : 'li',
          elementSize,
          highlight: highlight?.index === index,
          selected: false,
          disabled: false,
          tabIndex: -1,
          multiple,
          virtualized,
          index,
          ...noRowsProps,
          children: (
            <NoResult isLoading={isLoading} ellipsis={virtualized} title={text}>
              {text}
            </NoResult>
          )
        });
      },
      [elementSize, isLoading, t, noResultMessage, virtualized, highlight, renderNoOption, multiple]
    );

    const rowRenderer = useCallback(
      ({ index, ...rowProps }) => {
        const option = finalOptions[index];
        const selected = isSelected(option, value, getOptionSelected);
        const selectedOption = finalOptions.find((item) => getOptionSelected(getNewOptionData(searchValue), item));
        const isCreateOption = selectedOption && hasCreateOption && getOptionSelected(selectedOption, option);
        if (option?.loader) {
          return noRowsRenderer({ index, ...rowProps });
        }
        const sharedParams = {
          as: virtualized ? 'div' : 'li',
          virtualized,
          tabIndex: -1,
          onMouseMove: () => onHover(option, index),
          onMouseEnter: () => onHover(option, index),
          highlight: highlight?.index === index,
          elementSize,
          disabled: getOptionDisabled(option, value),
          value,
          index,
          searchValue,
          multiple,
          selected,
          option
        };
        if (isCreateOption) {
          const label = t('general:addItem', { item: getOptionLabel(option) });
          return renderNewOption({
            id: `${id}_add`,
            onClick: (event) => {
              if (getOptionDisabled(option, value)) return false;
              return create(event, option);
            },
            title: label,
            children: (
              <Text as="span" flex="1" overflowWrap="anywhere" wordWrap="break" ellipsis={virtualized}>
                {label}
              </Text>
            ),
            ...rowProps,
            ...sharedParams
          });
        }
        return renderOption({
          id: `${id}_option-${index}`,
          onClick: (event) => {
            if (getOptionDisabled(option, value)) return false;
            return onSelect(event, option);
          },
          title: getOptionLabel(option),
          children: (
            <>
              <Text as="span" flex="1" overflowWrap="anywhere" wordWrap="break" ellipsis={virtualized}>
                {getOptionLabel(option)}
              </Text>
              {selected && multiple && <Icon name="MdCheck" />}
            </>
          ),
          ...rowProps,
          ...sharedParams
        });
      },
      [
        t,
        create,
        virtualized,
        elementSize,
        highlight,
        onHover,
        onSelect,
        noRowsRenderer,
        getOptionLabel,
        getNewOptionData,
        getOptionSelected,
        getOptionDisabled,
        hasCreateOption,
        id,
        multiple,
        finalOptions,
        renderOption,
        renderNewOption,
        searchValue,
        value
      ]
    );

    const scrollToHighlightElement = useCallback(() => {
      const firstSelectedIndex = finalOptions.findIndex((option) => isSelected(option, value, getOptionSelected));
      const scrollIndex = firstSelectedIndex > 0 ? firstSelectedIndex : 0;
      setHighlight((hl) => {
        if (hl) {
          const isAvailable = !!finalOptions.find((opt) => equals(opt, hl.option));
          const isLast = hl.index >= finalOptions.length;
          return isAvailable ? hl : getNextHighlight(hl.index - (isLast ? 0 : 1), isLast ? -1 : 1);
        }
        if (listRef.current) listRef.current.scrollToRow(scrollIndex);
        return { option: finalOptions[scrollIndex], index: scrollIndex };
      });
    }, [getOptionSelected, finalOptions, value, getNextHighlight]);

    const onKeydown = useCallback(
      (event) => {
        if (highlight) {
          const { option, index } = highlight;
          if (['ArrowDown', 'ArrowUp', 'Tab'].includes(event.key)) {
            event.preventDefault();
            const incrementor = getIncrementor(event, option);
            const nextHighlight = getNextHighlight(index, incrementor);
            if (nextHighlight) setHighlight(nextHighlight);
          }
          if (event.key === 'Enter') {
            if (shouldPreventSelection(getOptionDisabled, option, value)) {
              event.stopPropagation();
              event.preventDefault();
              return false;
            }
            if (!option) {
              event.preventDefault();
              return onChange(null);
            }
            const isCreateOption = and(
              !!(finalOptions.find((item) => getOptionSelected(getNewOptionData(searchValue), item)) === option),
              hasCreateOption
            );
            if (isCreateOption) {
              create(event, option);
            } else {
              onSelect(event, option);
            }
          }
        }
        return true;
      },
      [
        value,
        create,
        finalOptions,
        getNewOptionData,
        getNextHighlight,
        getOptionSelected,
        getOptionDisabled,
        hasCreateOption,
        highlight,
        onSelect,
        onChange,
        searchValue
      ]
    );

    useEffect(() => {
      if (listRef.current) listRef.current.recomputeRowHeights();
    }, [value, finalOptions]);

    useEffect(() => {
      scrollToHighlightElement();
    }, [scrollToHighlightElement]);

    useImperativeHandle(ref, () => ({
      handleNavigation(event) {
        onKeydown(event);
      }
    }));

    return (
      <InfiniteLoader
        isRowLoaded={({ index }) => !!finalOptions[index]}
        rowCount={count}
        threshold={threshold}
        loadMoreRows={loadMore || (() => null)}>
        {({ onRowsRendered, registerChild }) => (
          <AutoSizerComponent disableHeight>
            {({ width }) => (
              <ListComponent
                scrollRef={scrollRef}
                threshold={threshold}
                ref={(r) => {
                  listRef.current = r;
                  registerChild(r);
                }}
                // height and rowHeight must be numbers
                height={parseInt(theme.elementSizes[elementSize], 10) * getVisibleCount(finalOptions, visibleCount)}
                rowHeight={parseInt(theme.elementSizes[elementSize], 10)}
                onRowsRendered={loadMore ? onRowsRendered : () => null}
                width={width}
                rowCount={finalOptions.length}
                rowRenderer={rowRenderer}
                noRowsRenderer={noRowsRenderer}
              />
            )}
          </AutoSizerComponent>
        )}
      </InfiniteLoader>
    );
  }
);

Options.propTypes = {
  /** array of selected options */
  value: oneOfType([any, array]),
  /** on selection change */
  onChange: func.isRequired,
  /** on creation option click, will not call onChange if defined (params: value, multiple, option) */
  onCreateOption: func,
  /** is create option in options list */
  hasCreateOption: bool.isRequired,
  /** get created option data */
  getNewOptionData: func.isRequired,
  /** used to determine the string value for a given option. It's used to fill the input (and the options list if renderOption is not provided).  */
  getOptionLabel: func.isRequired,
  /** used to determine if an option is selected */
  getOptionSelected: func.isRequired,
  /** used to determine if an option is disabled */
  getOptionDisabled: func.isRequired,
  /** custom item element */
  renderOption: func.isRequired,
  /** custom no item element */
  renderNoOption: func.isRequired,
  /** custom new item element */
  renderNewOption: func.isRequired,
  /** no result message */
  noResultMessage: string,
  /** is list virtualized, increases scroll performance for large lists, but forces uniform items height (https://github.com/bvaughn/react-virtualized/blob/master/docs/List.md) */
  virtualized: bool,
  /** visible number of rows */
  visibleCount: number,
  /** prefix the id of each option, the string rendered will be id_option-index */
  id: string.isRequired,
  /** hide selected elements */
  hideSelected: bool,
  /** custom item styled-system prop based on theme.elementSizes */
  elementSize: oneOfType([number, string, array, object]),
  /** array of options */
  options: array,
  /** is multiple */
  multiple: bool,
  /** is loading */
  isLoading: bool,
  /** search value from select */
  searchValue: string,
  /** InfiniteLoader loadMoreRows (https://github.com/bvaughn/react-virtualized/blob/master/docs/InfiniteLoader.md) */
  loadMore: func,
  /** InfiniteLoader rowCount (https://github.com/bvaughn/react-virtualized/blob/master/docs/InfiniteLoader.md) */
  count: number.isRequired,
  /** InfiniteLoader threshold (https://github.com/bvaughn/react-virtualized/blob/master/docs/InfiniteLoader.md) */
  threshold: number.isRequired
};

Options.defaultProps = {
  value: null,
  hideSelected: false,
  onCreateOption: null,
  noResultMessage: null,
  elementSize: 1,
  options: [],
  virtualized: false,
  visibleCount: 5,
  multiple: false,
  isLoading: false,
  searchValue: null,
  loadMore: null
};

export default Options;
