import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { compose, get, space, system } from 'styled-system';
import { isNil } from 'ramda';

export const globalStyles = css`
  body {
    cursor: grabbing;
    user-select: none;
  }
`;

export default {
  Slider: styled.div`
    position: relative;
    width: 100%;
    ${(p) =>
      p.showLabels &&
      `
      margin-top: ${p.theme.space[5]};
    `}
    ${(p) =>
      p.disabled &&
      `
      opacity: 0.6;
      pointer-events: none;
    `}
    ${compose(
      space,
      system({
        elementSize: {
          scale: 'elementSizes',
          property: 'height'
        }
      })
    )}
  `,
  Rail: styled.div`
    ${(p) => `
      height: ${p.theme.borderWidths[1]};
      background: ${p.theme.colors.grays[2]};
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    `}
    ${system({
      elementSize: {
        scale: 'elementSizes',
        properties: ['left', 'right'],
        transform: (val, scale) => !isNil(val) && `calc(${get(scale, val)} / 2);`
      }
    })}
  `,
  Indicator: styled.div`
    ${(p) => `
      background: ${p.error ? p.theme.colors.red : p.theme.colors.primary};
      position: absolute;
      left: ${p.left}%;
      right: ${p.right}%;
      top: 0;
      bottom: 0;
    `}
  `
};
