import { forwardRef } from 'react';
import { array, bool, node, number, object, oneOfType, string } from 'prop-types';

import Spinner from '../Spinner/Spinner';
import Box from '../Box/Box';
import Styled from './Button.styled';

const Button = forwardRef(({ isLoading, disabled, children, ...props }, ref) => (
  <Styled.Button ref={ref} {...props} disabled={disabled || isLoading} isLoading={isLoading}>
    {isLoading ? (
      <>
        <Styled.SpinnerContainer>
          <Spinner color="inherit" />
        </Styled.SpinnerContainer>
        <Box as="span" opacity="0">
          {children}
        </Box>
      </>
    ) : (
      children
    )}
  </Styled.Button>
));

Button.propTypes = {
  /** button type */
  type: string,
  /** styled-system variant prop */
  variant: oneOfType([string, array, object]),
  /** bare colors */
  bare: bool,
  /** custom styled-system prop based on theme.elementSizes */
  elementSize: oneOfType([number, string, array, object]),
  /** set min-width to elementSize value */
  iconOnly: bool,
  /** is loading */
  isLoading: bool,
  /** is disabled */
  disabled: bool,
  children: node
};

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
  bare: false,
  elementSize: 1,
  isLoading: false,
  disabled: false,
  children: null,
  iconOnly: false
};

export default Button;
