import { color, compose, layout, position, space, system } from 'styled-system';
import { css } from '@emotion/react';

export default (props) => css`
  ${compose(
    color,
    space,
    layout,
    position,
    system({
      pointerEvents: true
    })
  )(props)}
`;
