import { forwardRef } from 'react';
import { array, node, number, object, oneOfType, string } from 'prop-types';

import Styled from './Row.styled';

const Row = forwardRef((props, ref) => <Styled.Row ref={ref} {...props} />);

Row.propTypes = {
  /** will set the width (calc(100% + theme.space[gutter] * 2)) and negative margin (theme.space[gutter]) on each side to create gutters between its children */
  gutter: oneOfType([number, string, array, object]),
  /** styled system display prop */
  display: oneOfType([string, array, object]),
  /** styled system flex-wrap prop */
  flexWrap: oneOfType([string, array, object]),
  children: node
};

Row.defaultProps = {
  gutter: null,
  display: 'flex',
  flexWrap: 'wrap',
  children: null
};

export default Row;
