import { forwardRef } from 'react';
import { array, bool, number, object, oneOfType, string } from 'prop-types';

import Styled from './Tag.styled';

const Tag = forwardRef((props, ref) => <Styled.Tag ref={ref} {...props} />);

Tag.propTypes = {
  /** styled-system variant prop */
  variant: oneOfType([string, array, object]),
  /** custom styled-system prop based on theme.elementSizes */
  elementSize: oneOfType([number, string, array, object]),
  /** is tag selected */
  selected: bool
};

Tag.defaultProps = {
  variant: 'primary',
  elementSize: 0,
  selected: true
};

export default Tag;
