import styled from '@emotion/styled';
import { cover } from 'polished';

import Text from '../Text/Text';

export default {
  NoResult: styled(Text)`
    &:not(:only-child) {
      display: none;
    }
  `,
  Cover: styled.span`
    ${cover()}
    display: flex;
    align-items: center;
  `
};
