import { forwardRef } from 'react';
import { array, node, number, object, oneOfType, string } from 'prop-types';

import Box from '../Box/Box';

const Card = forwardRef(({ p, borderRadius, ...props }, ref) => (
  <Box ref={ref} p={p} borderRadius={borderRadius} {...props} />
));

Card.propTypes = {
  /** styled-system padding prop */
  p: oneOfType([number, string, array, object]),
  /** styled-system bg prop */
  bg: oneOfType([string, array, object]),
  /** styled-system overflow prop */
  overflow: oneOfType([string, array, object]),
  /** styled-system box-shadow prop */
  boxShadow: oneOfType([number, string, array, object]),
  /** styled-system border-radius prop */
  borderRadius: oneOfType([number, string, array, object]),
  children: node
};

Card.defaultProps = {
  p: 3,
  bg: 'light',
  overflow: 'hidden',
  boxShadow: 1,
  borderRadius: 1,
  children: null
};

export default Card;
