import styled from '@emotion/styled';
import { border, compose, layout, space } from 'styled-system';
import { cover, hideVisually } from 'polished';

import { Box, Error, Picture } from 'components/atoms';

export default {
  Clickable: styled(Box)`
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 191px;
  `,
  Error: styled(Error)`
    ${(p) => `
      margin-left: calc(25% + 2*${p.theme.space[2]} + ${p.theme.space[4]});
    `}
  `,
  UploadPicture: styled.label`
    ${(p) => `
      width: 75%;
      height: 191px;
      display: block;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      border: ${p.theme.borders[4]};
      border-radius: ${p.theme.radii[2]};
      margin-left: ${p.theme.space[2]};
    `}
    ${(p) => p.focus && `border-color: ${p.theme.colors.primary};`}
    ${(p) => (p.readOnly || p.disabled) && 'pointer-events: none;'}
    ${compose(layout, space, border)}
  `,
  Input: styled.input`
    ${hideVisually()}
  `,
  Picture: styled(Picture)`
    ${(p) => `
      display: block;
      height: 100%;
      border-radius: ${p.theme.radii[2]};
      margin-right: ${p.theme.space[2]};
    `}
  `,
  Placeholder: styled.span`
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(p) =>
      p.hasPicture()
        ? `
      color: ${p.theme.colors.light};
      background: ${p.theme.colors.blanket};
    `
        : `
      color: ${p.theme.colors.dark};
      background: ${p.theme.colors.light};
      opacity: 1;
    `}
    ${cover()}
    label:hover > & {
      opacity: 0.6;
    }
    ${(p) => p.focus && !p.hasPicture() && 'opacity: 1;'}
    ${border}
  `
};
