import styled from '@emotion/styled';
import { elementSize } from 'components/helpers';
import { compose, get, shadow, space, system, variant } from 'styled-system';

const getVariants = (props, styles) =>
  styles.reduce(
    (stack, style) => ({
      ...stack,
      [style.name]: {
        boxShadow: `0 0 0  ${props.theme.borderWidths[1]} inset ${get(props.theme.colors, style.bg)}`,
        ...(props.selected
          ? {
              color: style.color,
              bg: style.bg
            }
          : {
              color: style.bg,
              bg: 'transparent'
            })
      }
    }),
    {}
  );

export default {
  Tag: styled.span`
    ${(p) => `
      border-radius: ${p.theme.radii[1]};
      overflow: hidden;
      font-size: ${p.theme.fontSizes[2]};
      display: inline-flex;
      align-items: center;
      vertical-align: middle;
      max-width: 100%;
    `}
    ${(p) =>
      variant({
        variants: getVariants(p, [
          {
            name: 'primary',
            color: 'light',
            bg: 'primary'
          },
          {
            name: 'secondary',
            color: 'dark',
            bg: 'secondary'
          },
          {
            name: 'warning',
            color: 'dark',
            bg: 'yellow'
          },
          {
            name: 'success',
            color: 'light',
            bg: 'green'
          },
          {
            name: 'error',
            color: 'light',
            bg: 'red'
          },
          {
            name: 'dark',
            color: 'light',
            bg: 'dark'
          },
          {
            name: 'light',
            color: 'dark',
            bg: 'light'
          }
        ])
      })}
    ${elementSize(['height'])}
    ${system({
      elementSize: {
        scale: 'space',
        properties: ['paddingLeft', 'paddingRight'],
        transform: (val, scale) => get(scale, parseInt(val, 10) + 2)
      },
      cursor: true
    })}
    ${compose(shadow, space)}
  `
};
