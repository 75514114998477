import { css, keyframes } from '@emotion/css';
import styled from '@emotion/styled';
import { border, compose, layout, space, system } from 'styled-system';
import { cover } from 'polished';

export const getLoadingAnimation = (theme) => css`
  animation: ${keyframes`
    0% { color: ${theme.colors.grays[1]} }
    50% { color: ${theme.colors.grays[0]} }
    100% { color: ${theme.colors.grays[1]} }
  `} 1s linear infinite;
`;

export default {
  Picture: styled.figure`
    position: relative;
    overflow: hidden;
    border-radius: ${(p) => p.theme.radii[1]};
    ${compose(space, layout, border)}
  `,
  Img: styled.img`
    width: 100%;
    height: 100%;
    border-radius: ${(p) => p.theme.radii[1]};
    ${(p) =>
      p.noImg &&
      `
      padding-left: 100%;
      display: flex;
    `}
    ${compose(
      border,
      system({
        objectFit: true,
        objectPosition: true,
        aspectRatio: true
      })
    )}
  `,
  Placeholder: styled.span`
    background-color: ${(p) => p.theme.colors.grays[0]};
    border-radius: ${(p) => p.theme.radii[2]};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    ${cover()}
    ${border}
  `
};
