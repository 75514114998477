import { useEffect } from 'react';
import { useSortBy, useTable } from 'react-table';
import { any, array, arrayOf, bool, func, node, number, object, oneOfType, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { pick } from '@styled-system/props';
import { equals, omit } from 'ramda';

import { usePrevious } from 'hooks';

import { NoResult } from 'components/atoms';

import Styled from './Table.styled';

const Table = ({
  isLoading,
  columns,
  data,
  limit,
  sortable,
  tableParams,
  onSort,
  noDataMessage,
  getRowProps,
  headCellsProps,
  ...props
}) => {
  const { t } = useTranslation();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy }
  } = useTable(
    {
      columns,
      data,
      disabledSortBy: !sortable,
      manualSortBy: true,
      ...tableParams
    },
    useSortBy
  );

  const prevSortBy = usePrevious(sortBy, true);

  useEffect(() => {
    if (!equals(sortBy, prevSortBy)) {
      onSort(sortBy);
    }
  }, [sortBy, prevSortBy, onSort]);

  return (
    <Styled.Container {...props}>
      {(!!limit || !!data.length) && (
        <Styled.Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <Styled.Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const { key: headerKey, ...headerProps } = column.getHeaderProps({
                    ...pick(column.cellProps),
                    ...(sortable && omit(['title'], column.getSortByToggleProps()))
                  });
                  return (
                    <Styled.Cell as="th" bg="transparent" key={headerKey} {...headerProps} {...headCellsProps}>
                      {column.render('Header')}
                      {sortable && column.canSort && (
                        <Styled.Arrow
                          isSorted={column.isSorted}
                          name={column.isSortedDesc === false ? 'MdArrowDropUp' : 'MdArrowDropDown'}
                        />
                      )}
                    </Styled.Cell>
                  );
                })}
              </Styled.Row>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const { key: rowKey, ...rowProps } = row.getRowProps(getRowProps(row));
              return (
                <Styled.Row key={rowKey} isLoading={isLoading} clickable={!!rowProps.onClick} {...rowProps}>
                  {row.cells.map((cell) => {
                    const { key: cellKey, ...cellProps } = cell.getCellProps(pick(cell.column.cellProps));
                    return (
                      <Styled.Cell key={cellKey} {...cellProps}>
                        {cell.render('Cell')}
                      </Styled.Cell>
                    );
                  })}
                </Styled.Row>
              );
            })}
            {isLoading &&
              !!limit &&
              !data.length &&
              Array.from({ length: limit }, (_, index) => (
                <Styled.Row key={index} index={index} noData>
                  <Styled.Cell colSpan="999" />
                </Styled.Row>
              ))}
          </tbody>
        </Styled.Table>
      )}
      <NoResult isLoading={isLoading}>{noDataMessage || t('noResult')}</NoResult>
    </Styled.Container>
  );
};

Table.propTypes = {
  /** array of column parameters, field references the prop from data to display */
  columns: arrayOf(
    shape({
      Header: node,
      accessor: oneOfType([array, string, func])
    })
  ),
  /** table data */
  data: arrayOf(any),
  /** head cells props, also accepts colors, layout and space from styled system, and sicky: bool to fix the head of the table */
  headCellsProps: object,
  /** function that returns an object containing row props and takes the row information as parameter */
  getRowProps: func,
  /** is loading */
  isLoading: bool,
  /** is sortable */
  sortable: bool,
  /** useTable and useSortBy params */
  tableParams: object,
  /** on sort function (if tableParams.manualSortBy is true) */
  onSort: func,
  /** row limit, used to display a placeholder table while loading */
  limit: number,
  /** message to display when no data, defaults to t('noResult') if null */
  noDataMessage: string,
  /** styled-system overflow prop */
  overflow: oneOfType([string, array, object]),
  /** styled-system width prop */
  width: oneOfType([number, string, array, object])
};

Table.defaultProps = {
  columns: [],
  data: [],
  headCellsProps: null,
  getRowProps: () => {},
  isLoading: false,
  sortable: false,
  tableParams: null,
  onSort: () => null,
  limit: null,
  noDataMessage: null,
  overflow: 'auto',
  width: '100%'
};

export default Table;
