import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { Box } from 'components/atoms';

const loadingAnimation = keyframes`
  0% { opacity: 1; top: 0; }
  60% { opacity: 0; top: 20px; }
  100% { opacity: 0; top: 20px; }
`;

const errorAnimation = keyframes`
  0% { opacity: 0; top: 20px; }
  100% { opacity: 1; top: 0px; }
`;

export default {
  FadeOut: styled(Box)`
    position: absolute;
    width: 150px;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    ${() => css`
      animation: ${css`
        ${loadingAnimation} 0.3s linear 0.2s forwards;
      `};
    `}
  `,
  FadeIn: styled(Box)`
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 84px;
    ${() => css`
      animation: ${css`
        ${errorAnimation} 0.3s linear 0.2s forwards;
      `};
    `}
  `
};
