import { useContext } from 'react';
import { array, bool, node } from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

import { UserContext } from 'context';

const AuthRoute = ({ children, offlineOnly, group }) => {
  const { userId, role = false } = useContext(UserContext);
  const location = useLocation();
  const isLogged = !!userId;
  const hasRequiredRole = group ? group.includes(role) : offlineOnly && !isLogged;
  const { requestedPath } = location?.state || {};

  if (!hasRequiredRole) {
    const destination = isLogged ? '/' : '/login';
    const state = isLogged ? null : { requestedPath: location.pathname };

    if (requestedPath) return <Navigate to={requestedPath} />;
    return <Navigate to={destination} state={state} />;
  }

  return children;
};

AuthRoute.propTypes = {
  children: node,
  group: array,
  offlineOnly: bool
};

AuthRoute.defaultProps = {
  children: null,
  group: null,
  offlineOnly: false
};

export default AuthRoute;
