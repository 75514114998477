import { Navigate, Route, Routes } from 'react-router-dom';

import { Error, WebForm } from 'pages';

const ProjectRoutes = () => (
  <Routes>
    <Route path="/error/:code" exact element={<Error />} />
    <Route path="/" exact element={<WebForm />} />
    <Route path="*" element={<Navigate to="/error/404" replace />} />
  </Routes>
);

export default ProjectRoutes;
