import { useMemo } from 'react';
import { bool } from 'prop-types';
import { pathOr } from 'ramda';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import i18n from 'translate/i18n';

import { ACCEPTED_IMAGE_FORMATS } from 'utils/constants';
import { formatLocale } from 'utils/data';

import dangerIcon from 'assets/danger.svg';
import { UploadPicture } from 'components/molecules';
import { Box, Card, Icon, Link, Picture, Text } from 'components/atoms';

import validators from 'validations/photo';

import enRules from 'assets/files/rules_en.pdf';
import frRules from 'assets/files/rules_fr.pdf';
import nlRules from 'assets/files/rules_nl.pdf';

const FormPhoto = ({ isPictureMandatory }) => {
  const {
    control,
    formState: { errors },
    getValues
  } = useFormContext();
  const { t } = useTranslation(['fields', 'validation']);

  const validations = useMemo(() => validators(t, getValues, isPictureMandatory), [getValues, isPictureMandatory, t]);
  const relevantRules = useMemo(
    () => ({
      en: enRules,
      fr: frRules,
      nl: nlRules
    }),
    []
  );

  return (
    <Box display="flex" flexDirection="column" flex={1} py={2} mx={2}>
      <Card display="flex" flexDirection="row" border={5} borderRadius={2} p={2} my={3}>
        <Box display="flex" flexDirection="row" flex={1} justifyContent="flex-start">
          <Box display="flex" flexDirection="column" justifyContent="center" mr={3}>
            <Box dislay="flex" borderRadius={2} bg="paleYellow" p={2}>
              <Picture src={dangerIcon} alt="Important" display="flex" />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <Text color="fulvous" fontSize={14} fontWeight={2}>
              {t('fields:photo.properties')}
            </Text>
            <Text fontSize={14}>{t('fields:photo.discoverRules')}</Text>
          </Box>
        </Box>
        <Box justifyContent="flex-end" my="auto" mr={3}>
          <Link
            as="a"
            href={relevantRules?.[formatLocale(i18n.language)]}
            color="primary"
            download={`rules_${formatLocale(i18n.language)}`}>
            {t('fields:photo.seeRules')}
          </Link>
        </Box>
      </Card>

      <Controller
        control={control}
        name="photo"
        rules={validations?.photo}
        render={({ field: { onChange, value } }) => (
          <UploadPicture
            name="upload-zone"
            objectFit="contain"
            accept={ACCEPTED_IMAGE_FORMATS.ACCEPT_VALUES.join(',')}
            value={value}
            onChange={onChange}
            renderIcon={() => <Icon name="MdPerson" color="grays.6" size={156} />}
            placeholderContent={{
              title: `${t('fields:photo.upload')}${isPictureMandatory ? ' *' : ''}`,
              listFormats: ACCEPTED_IMAGE_FORMATS.FORMAT_NAMES.slice(0, 3).join(' / '),
              photoNameDisclaimer: t('fields:photo.photoNameDisclaimer')
            }}
            uploadPictureError={pathOr(null, ['photo', 'message'], errors)}
            valueInForm={getValues('photo')}
            mt={2}
          />
        )}
      />
    </Box>
  );
};

FormPhoto.propTypes = {
  isPictureMandatory: bool.isRequired
};

export default FormPhoto;
