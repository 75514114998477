import { reject } from 'ramda';

export const getVisibleCount = (options, visibleCount) =>
  options.length < visibleCount ? options.length || 1 : visibleCount;

export const isSelected = (option, value, getSelected) => !!(value || []).find((curr) => getSelected(option, curr));

// return new list with clicked item added/removed
const toggleValue = (option, value, getSelected) =>
  isSelected(option, value, getSelected) ? reject((curr) => getSelected(option, curr), value) : [...value, option];

export const shouldPreventSelection = (getOptionDisabled, option, value) =>
  getOptionDisabled(option, value) || option?.loader;

// get list to return as value
export const getNewValue = (option, value, multiple, getSelected) =>
  !multiple ? [option] : toggleValue(option, value, getSelected);

export const getIncrementor = (event, option) => {
  const isUp = event.key === 'ArrowUp' || (event.key === 'Tab' && event.shiftKey);
  if (option?.loader && !isUp) return 0;
  return isUp ? -1 : 1;
};
