import { forwardRef } from 'react';
import { array, bool, func, node, number, object, oneOfType, string } from 'prop-types';

import Styled from './Text.styled';

const Text = forwardRef((props, ref) => <Styled.Text ref={ref} {...props} />);

Text.propTypes = {
  /** element to render as */
  as: oneOfType([string, object, func]),
  /* custom ellipsis styled-system prop, provide a number of lines to clamp, true = 1 */
  ellipsis: oneOfType([number, string, bool, array, object]),
  children: node
};

Text.defaultProps = {
  as: 'p',
  ellipsis: null,
  children: null
};

export default Text;
