import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { color, system } from 'styled-system';
import shouldForwardProp from '@styled-system/should-forward-prop';

import { boxProps, ellipsis } from 'components/helpers';

export default {
  Link: styled(Link, {
    shouldForwardProp: (p) => shouldForwardProp(p) && p !== 'hoverColor'
  })`
    ${ellipsis}
    ${boxProps}
    &:active,
    &:visited {
      ${color}
    }
    &:hover,
    &:focus {
      ${system({
        hoverColor: {
          scale: 'colors',
          property: 'color'
        }
      })}
    }
    outline: none;
  `
};
