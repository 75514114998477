import styled from '@emotion/styled';
import { hideVisually } from 'polished';
import { border, color, compose, flexbox, get, height, layout, space, system, typography, width } from 'styled-system';
import { isNil } from 'ramda';

import { mimicLabel } from 'components/helpers';

export const stateStyle = (p) => {
  const bg = `background: ${p.theme.colors.light};`;
  if (p.error) {
    return `
      ${bg}
      border-color: ${p.theme.colors.error};
    `;
  }
  if (p.disabled) {
    return `
      background: ${p.theme.colors.grays[0]};
      border-color: transparent;
    `;
  }
  if (p.readOnly) {
    return `
      ${bg}
      border-color: ${p.theme.colors.grays[0]};
    `;
  }
  if (p.focus) {
    return `
      ${bg}
      border-color: ${p.theme.colors.primary};
    `;
  }
  return `
    ${bg}
    border-color: ${p.theme.colors.grays[3]};
  `;
};

export const mountedLabelColor = (p) => {
  if (p.ofError && p.hasValue) {
    return `${p.theme.colors.error}`;
  }
  if (p.ofReadOnly) {
    return `${p.theme.colors.grays[3]}`;
  }
  if (p.ofFocus && p.hasValue) {
    return `${p.theme.colors.primary}`;
  }
  if (p.ofSuccess) {
    return `${p.theme.colors.success}`;
  }
  return 'inherit';
};

export default {
  Container: styled.div`
    ${compose(layout, flexbox, space)}
  `,
  Main: styled.label`
    display: flex;
    flex-direction: column;
    min-width: 320px;
    ${compose(flexbox, mimicLabel)}
  `,
  Content: styled.div`
    ${(p) => `
      position: relative;
      flex: 1;
      display: flex;
      border-radius: ${p.theme.radii[2]};
      border-width: ${p.theme.borderWidths[1]};
      border-style: solid;
      transition: border-color ${p.theme.transition}, background-color ${p.theme.transition};
    `}
    ${(p) =>
      p.disabled &&
      `
      pointer-events: none;
      opacity: 0.5;
    `}
    ${(p) => p.hideInput && hideVisually()}
    ${stateStyle}
    ${compose(border, color)}
  `,
  Input: styled.input`
    ${(p) => `
      font-size: ${p.theme.fontSizes[3]};
      line-height: ${p.theme.lineHeight};
      padding-left: ${p.hasStartIcon ? '0;' : `${p.theme.space[3]};`}
      padding-right: ${p.hasEndIcon || p.hasReset ? '0;' : `${p.theme.space[3]};`}
      background: none;
      border: none;
      flex: 1;
      min-width: 0;
      resize: none;
      outline: none;
      -moz-appearance: textfield;
      ::-webkit-search-cancel-button,
      ::-webkit-clear-button,
      ::-webkit-inner-spin-button {
        display: none;
      }

      &:placeholder-shown + span {
        background-color: transparent;
        transform: translate(calc(-5px + ${p.hasStartIcon ? '32px' : p.theme.space[3]}), -50%);
        transition: transform .2s linear, background-color 0s linear 0.1s;
      }
      &::placeholder, &:placeholder {
        color: transparent;
      }
    `}
    ${(p) =>
      system({
        elementSize: {
          scale: 'elementSizes',
          properties: ['paddingTop', 'paddingBottom'],
          transform: (val, scale) =>
            !isNil(val) &&
            `calc((
                ${get(scale, val)} - ${p.theme.borderWidths[1]} * 2 -
                ${p.theme.fontSizes[2]} * ${p.theme.lineHeight}
              ) / 2)`
        }
      })}
    ${(p) =>
      system({
        elementSize: {
          scale: 'elementSizes',
          property: 'height',
          transform: (val, scale) => {
            if (isNil(val)) return null;
            return p.isTextarea
              ? `calc(${get(scale, val)} - 
                ${p.theme.borderWidths[1]} * 2 + 
                ${p.theme.fontSizes[2]} * 
                ${p.theme.lineHeight * 4});`
              : `calc(${get(scale, val)} - ${p.theme.borderWidths[1]} * 2)`;
          }
        }
      })}
    ${compose(
      color,
      space,
      height,
      width,
      typography,
      system({
        cursor: true
      })
    )}
  `,
  Placeholder: styled.span`
    ${(p) => `
      font-size: ${p.theme.fontSizes[3]};
      background-color: ${p.theme.colors.light};
      border-radius: 10px;
      padding: 0 5px;
      position: absolute;
      color: ${mountedLabelColor(p)};
      top: 50%;
      transform: scale(0.9) translate(8px, -41px);
      transform-origin: 0 50%;
      transform-style: preserve-3d;
      transition: transform .15s linear;
  `}
  `
};
