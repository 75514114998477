import { useEffect, useMemo } from 'react';
import { pathOr } from 'ramda';
import { bool, func, object } from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box } from 'components/atoms';
import { Form, LegalInfos, LoadingCard } from 'components/organisms';

const WebForm = ({ checkToken, isLoadingForm, baseData }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const params = useMemo(() => new URLSearchParams(location.search), [location]);
  const token = useMemo(() => params.get('token'), [params]);
  const isPictureMandatory = useMemo(() => baseData?.isPicRequired ?? true, [baseData?.isPicRequired]);

  useEffect(() => {
    checkToken(token);
  }, [checkToken, token]);

  // TODO: Using test route with SSL + Certificate will load forever if it fails. Keep that in mind and check in the future.
  useEffect(() => {
    const { returnCode } = baseData;
    if (!returnCode) return;
    if (returnCode !== 200) navigate(`error/${returnCode}`);
  }, [baseData, navigate]);

  return (
    <Box display="flex" flexDirection="row" flex={1}>
      {/* The check on baseData here is to avoid the form flashing before the redirection. There might be a better way */}
      {isLoadingForm || baseData?.returnCode !== 200 ? (
        <Box display="flex" flex={1}>
          <LoadingCard />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" flex={1} bg="paleBlue">
          <Box
            display="flex"
            flexDirection={['column', null, null, null, isPictureMandatory ? 'row' : 'column']}
            flex={1}
            m={[0, 0, 7, 13, 13]}>
            <Form token={token} isPictureMandatory={isPictureMandatory} />
            <LegalInfos isPictureMandatory={isPictureMandatory} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

WebForm.propTypes = {
  checkToken: func.isRequired,
  isLoadingForm: bool.isRequired,
  baseData: object.isRequired
};

const mapState = ({ carrier: { baseData }, loading }) => ({
  baseData,
  isLoadingForm:
    pathOr(false, ['effects', 'carrier', 'checkToken'], loading) ||
    pathOr(false, ['effects', 'country', 'getCountries'], loading)
});

const mapDispatch = ({ carrier: { checkToken } }) => ({
  checkToken
});

export default connect(mapState, mapDispatch)(WebForm);
