import { useEffect, useState } from 'react';
import { func, number } from 'prop-types';
import { isNil } from 'ramda';

import { useDebounce } from 'hooks';

import Field from '../Field/Field';

const SearchField = ({ onChange, debounceTime, ...props }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, debounceTime);

  useEffect(() => {
    if (!isNil(debouncedSearchTerm)) {
      onChange(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, onChange]);

  return <Field onValueChange={setSearchTerm} resetable endIcon="MdSearch" value={searchTerm} {...props} />;
};

SearchField.propTypes = {
  onChange: func,
  debounceTime: number
};

SearchField.defaultProps = {
  onChange: () => null,
  debounceTime: 500
};

export default SearchField;
