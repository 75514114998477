import { useEffect, useRef, useState } from 'react';
import { equals } from 'ramda';
import { any, array, bool, node, number, object, oneOfType, string } from 'prop-types';

import Styled from './Handle.styled';

const Handle = ({ dragging, value, focused, left, children, ...props }) => {
  const ref = useRef();
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (equals(dragging, value) || equals(focused, value)) ref.current.focus();
  }, [dragging, value, ref, focused]);

  return (
    <Styled.Handle left={left} focus={focus}>
      <Styled.Button
        {...props}
        dragging={dragging}
        value={value}
        type="button"
        ref={ref}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <Styled.Text>{children}</Styled.Text>
    </Styled.Handle>
  );
};

Handle.propTypes = {
  /** is dragging */
  dragging: any,
  /** handle value */
  value: any,
  /** is focused */
  focused: any,
  /** left position value */
  left: number,
  /** is outlined */
  outline: bool.isRequired,
  /** custom styled-system prop based on theme.elementSizes */
  elementSize: oneOfType([number, string, array, object]),
  children: node
};

Handle.defaultProps = {
  dragging: null,
  value: null,
  focused: null,
  left: 0,
  elementSize: 0,
  children: null
};

export default Handle;
