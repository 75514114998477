export const setNativeValue = (element, value) => {
  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;
  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(element, value);
  } else {
    valueSetter.call(element, value);
  }
};

export const displayReset = (p) => !!((p.manualReset || (p.resetable && p.showReset)) && !p.disabled && !p.readOnly);

// eslint-disable-next-line complexity
export const hasAccessory = (p, type) =>
  !!(
    p[`${type}Icon`] ||
    p[`${type}TextIcon`] ||
    p[`render${type.charAt(0).toUpperCase() + type.slice(1)}Accessory`] ||
    p[`${type}AccessoryProps`]?.icon ||
    p[`${type}AccessoryProps`]?.textIcon ||
    (type === 'start' && p.readOnly) ||
    (type === 'end' && p.error)
  );

export const getType = ({ type }) => (type === 'textarea' ? { as: type, isTextarea: true } : { type });

export const isDisabled = (p) => p.disabled || p.hideInput;
