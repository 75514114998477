import { useTranslation } from 'react-i18next';
import { bool } from 'prop-types';

import { Box, Card, Heading, Link, Text } from 'components/atoms';

const LegalInfos = ({ isPictureMandatory }) => {
  const { t } = useTranslation('legalInfos');
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={[1, 1, 1, 1, 'none']}
      width={[1, 1, 1, 1, isPictureMandatory ? 1 / 3 : 1]}
      mt={[6, 6, 6, 6, isPictureMandatory ? 0 : 6]}>
      <Card
        bg="light"
        display="flex"
        flexDirection="column"
        flex={1}
        borderRadius={[0, 0, 4, 4, 4]}
        border={3}
        boxShadow={1}
        px={4}
        py={6}
        mx={[0, 0, 0, 0, 4]}>
        <Heading
          textAlign="center"
          fontSize={24}
          pt={[4, 4, 4, 4, isPictureMandatory ? 6 : 4]}
          pb={[8, 8, 8, 8, isPictureMandatory ? 12 : 8]}>
          {t('legalInfos:title')}
        </Heading>
        <Box mx={5}>
          <Text fontSize={16} mb={4}>
            {t('legalInfos:intro')}
          </Text>
          <Text fontSize={16} mb={4}>
            {t('legalInfos:pledge')}
          </Text>
          <Box>
            <Text fontSize={16}>
              {`${t('legalInfos:closure.content')} `}
              <Link as="a" href={t('legalInfos:closure.link')} target="_blank" color="primary">
                {t('legalInfos:closure.linkText')}
              </Link>
            </Text>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

LegalInfos.propTypes = {
  isPictureMandatory: bool.isRequired
};

export default LegalInfos;
