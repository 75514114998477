import { useTranslation } from 'react-i18next';

import { formatLocale } from 'utils/data';

import i18n from 'translate/i18n';

import { renderOnCondition } from 'utils/component';

import { DropdownToggle } from 'components/molecules';
import { Box, Icon, Item, Text } from 'components/atoms';

const Translate = () => {
  const { t } = useTranslation('translate');

  return (
    <DropdownToggle
      renderElement={({ elementRef, ...props }) => (
        <Box ref={elementRef} display="flex" flexDirection="row" cursor="pointer" {...props}>
          <Text fontSize={14} color="light">
            {formatLocale(i18n.language).toUpperCase()}
          </Text>
          <Icon
            name={elementRef?.current?.isShown === false ? 'MdKeyboardArrowUp' : 'MdKeyboardArrowDown'}
            size={20}
            color="light"
          />
        </Box>
      )}
      contentProps={{
        borderRadius: '10px',
        boxShadow: 1
      }}>
      <ul>
        <Item
          onClick={() => i18n.changeLanguage('en')}
          onKeyUp={(e) => e.key === 'Enter' && i18n.changeLanguage('en')}
          id="translate-en"
          elementSize={0}>
          <Box display="flex" flexDirection="row">
            <Text mr={2}>{t('translate:en')}</Text>
            {renderOnCondition(i18n.language === 'en', () => (
              <Icon name="MdCheck" color="primary" />
            ))}
          </Box>
        </Item>
        <Item
          onClick={() => i18n.changeLanguage('fr')}
          onKeyUp={(e) => e.key === 'Enter' && i18n.changeLanguage('fr')}
          id="translate-fr"
          elementSize={0}>
          <Box display="flex" flexDirection="row">
            <Text mr={2}>{t('translate:fr')}</Text>
            {renderOnCondition(i18n.language === 'fr', () => (
              <Icon name="MdCheck" color="primary" />
            ))}
          </Box>
        </Item>
        <Item
          onClick={() => i18n.changeLanguage('nl')}
          onKeyUp={(e) => e.key === 'Enter' && i18n.changeLanguage('nl')}
          id="translate-nl"
          elementSize={0}>
          <Box display="flex" flexDirection="row">
            <Text mr={2}>{t('translate:nl')}</Text>
            {renderOnCondition(i18n.language === 'nl', () => (
              <Icon name="MdCheck" color="primary" />
            ))}
          </Box>
        </Item>
      </ul>
    </DropdownToggle>
  );
};

export default Translate;
