import { equals, sortBy } from 'ramda';

export const moveEvents = ['mousemove', 'touchmove'];
export const endEvents = ['mouseup', 'touchend'];

export const getValuePosition = (value, scale) => scale.findIndex((scaleItem) => equals(scaleItem, value));

export const getHandleProps = (value, scale, step) => {
  const index = getValuePosition(value, scale);
  if (index === -1) return 0;
  return { left: index * step };
};

export const getIndicatorLeft = (handles, options) =>
  handles.length >= 2 || (handles.length && options.toMax) ? handles[0].left : 0;

export const getIndicatorRight = (handles, options) =>
  handles.length >= 2 || (handles.length && options.fromMin) ? 100 - handles[handles.length - 1].left : 0;

export const getStep = (scale) => 100 / (scale.length - 1);

export const validMin = (values, scale, minRange) => {
  let isValid = true;
  values.reduce((prev, curr, index) => {
    const currPos = getValuePosition(curr, scale);
    if (Math.abs(currPos - prev) < minRange && index) {
      isValid = false;
    }
    return currPos;
  }, 0);
  return isValid;
};

export const getNextValues = ({ valuePos, iterator, handles, scale, minRange, index }) => {
  const nextPos = valuePos + iterator;
  const nextValue = scale[nextPos];
  if (nextValue) {
    const newValues = sortBy(
      (val) => getValuePosition(val, scale),
      Object.assign([...handles.map((item) => item.handle)], { [index]: nextValue })
    );
    if (validMin(newValues, scale, minRange)) {
      return { selected: nextValue, values: newValues };
    }
    return getNextValues({ valuePos: nextPos, iterator, handles, scale, minRange, index });
  }
  return null;
};
