import {
  background,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  system,
  typography
} from 'styled-system';
import { ellipsis as polishedEllipsis } from 'polished';
import { flatten, is, isNil, keys, omit, pathOr, pick, reject, values } from 'ramda';

export const transformValue = (type, multiple, value) => {
  // used in Select, Select.Items and Slider
  // transform value received into an array for easier manipulation
  // transform value emitted into a single string value if single, array if multiple
  const flat = !isNil(value) ? flatten([value]) : [];
  return type === 'out' && !multiple ? flat[0] : flat;
};

export const openFile = async (getFile) => {
  const file = await getFile();
  if (!file) return;
  const { name, type, data } = file;
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const blob = new Blob([data || file], { type: type || 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE doesn't allow using a blob object directly as link href.
    // Workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, name);
    return;
  }

  // Other browsers
  // Create a link pointing to the ObjectURL containing the blob
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', name);
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(blobURL);
  }, 100);
};

export const getError = (path, errors, nameSpace = 'validation') => {
  const errorName = pathOr(null, flatten([path]), errors);
  const link = nameSpace ? ':' : '';
  return errorName ? `${nameSpace || ''}${link}${errorName}` : null;
};

export const isSelected = (option, value, getSelected) => !!(value || []).find((curr) => getSelected(option, curr));

// return new list with clicked item added/removed
export const toggleValue = (option, value, getSelected) =>
  isSelected(option, value, getSelected) ? reject((curr) => getSelected(option, curr), value) : [...value, option];

export const isVisible = (element) => {
  if (!element) return false;
  const { width, height } = element.getBoundingClientRect();
  return width && height;
};

export const getRadiusProps = (props) =>
  pick(
    [
      'borderRadius',
      'borderTopLeftRadius',
      'borderTopRightRadius',
      'borderBottomLeftRadius',
      'borderBottomRightRadius'
    ],
    props
  );

export const mimicLabel = system({
  mimicLabel: {
    scale: 'mimicLabel',
    property: 'paddingTop',
    transform: (val, scale) => {
      if (isNil(val)) return null;
      return val ? scale : 0;
    }
  }
});

export const elementSize = (properties) =>
  system({
    elementSize: {
      scale: 'elementSizes',
      properties
    }
  });

const addMaxWidthQuery = (vals, i, p) => {
  if ([false, 0].includes(vals[i]) && p.theme.breakpoints[i - 1]) {
    return ` and (max-width: ${p.theme.breakpoints[i - 1]})`;
  }
  return '';
};

const findNextIndex = (vals, index, direction) => {
  const next = vals[index + direction];
  if ((next && direction === -1) || [false, 0].includes(next)) return index + direction;
  if ([-1, vals.length + 2].includes(index + direction)) return null;
  return findNextIndex(vals, index + direction, direction);
};

const getNumberValue = (v) => (v === true ? 1 : v);

const addSingleOrMultiple = (prev, curr) => {
  if (prev > 1 && curr === 1) {
    return {
      display: 'block',
      whiteSpace: 'nowrap'
    };
  }
  if (prev === 1 && curr > 1) {
    return {
      display: '-webkit-box',
      whiteSpace: 'normal'
    };
  }
  return {};
};

export const ellipsis = (p) => {
  if (p.ellipsis) {
    let vals = p.ellipsis;
    // turn the value of the prop to an array no matter what is passed
    if (is(Object, p.ellipsis) && !p.ellipsis[0]) {
      // if any key not aliased in the theme is present in an object value, it will be considered as the base value (as per styled-system doc)
      const baseValue = omit(p.theme.breakpointsKeys, vals);
      const bpValues = pick(p.theme.breakpointsKeys, vals);
      const baseValueKey = keys(baseValue)[0];
      vals = values({
        ...(baseValueKey ? pick([baseValueKey], baseValue) : { _: null }),
        ...{ ...p.theme.breakpointsKeys.reduce((acc, curr) => ({ ...acc, [curr]: null }), {}) },
        ...bpValues
      });
    }
    if (!is(Array, vals)) vals = [vals];
    //
    const couldReset = vals.findIndex((v) => [false, 0].includes(v)) > -1;
    const res = vals.reduce((acc, v, i) => {
      // find the previous usable value (no undefined, null, 0 or false)
      const previous = findNextIndex(vals, i, -1);
      // find the next "disable" value (0 or false)
      const next = findNextIndex(vals, i, 1);
      // true is considered as a 1 line ellipsis
      const current = getNumberValue(v);
      if (current) {
        // if previous was a usable value, only add the lines we need to grow/shrink the number of lines
        if (vals[previous]) {
          return {
            ...acc,
            [`@media screen and (min-width: ${p.theme.breakpoints[i - 1]})${addMaxWidthQuery(vals, next, p)}`]: {
              WebkitLineClamp: current,
              maxHeight: `${p.theme.lineHeight * current}em`,
              ...addSingleOrMultiple(getNumberValue(vals[previous]), current)
            }
          };
        }
        const mediaEllipsis = {
          // force multiline style ellipsis even for 1 line to avoid conflicting styles between media queries
          ...omit(['WebkitLineClamp'], polishedEllipsis(null, 2)),
          maxHeight: `${p.theme.lineHeight * current}em`,
          WebkitLineClamp: current,
          ...(current === 1 && {
            display: 'block',
            whiteSpace: 'nowrap'
          })
        };
        // if no max-width needs to be added, that means we don't need a media query for the base style
        if (!couldReset && i === 0) {
          return { ...acc, ...mediaEllipsis };
        }
        // in any other case, produce the needed media query with all ellipsis styles
        return {
          ...acc,
          [`@media screen ${
            !couldReset || i > 0 ? `and (min-width: ${p.theme.breakpoints[i - 1]})` : ''
          }${addMaxWidthQuery(vals, next, p)}`]: {
            ...mediaEllipsis
          }
        };
      }
      return acc;
    }, {});
    return res;
  }
  return null;
};

export const boxProps = compose(
  typography,
  space,
  layout,
  flexbox,
  position,
  border,
  color,
  shadow,
  background,
  grid,
  elementSize(['minHeight']),
  mimicLabel,
  ellipsis,
  system({
    textTransform: true,
    textDecoration: true,
    transform: true,
    whiteSpace: true,
    wordBreak: true,
    overflowWrap: true,
    wordWrap: true,
    pointerEvents: true,
    cursor: true
  })
);
