import {
  formatIncompletePhoneNumber,
  getCountries,
  getCountryCallingCode,
  isValidPhoneNumber,
  parseIncompletePhoneNumber,
  parsePhoneNumber
} from 'libphonenumber-js';

export const codes = getCountries().map((code) => ({ country: code, countryCallingCode: getCountryCallingCode(code) }));

export const getValue = (value, defaultValue) => (value !== undefined ? value : defaultValue);

export const getSelectValue = (value) => (value?.country ? value : null);

export const getOptionText = (option) => `${option?.country} +${option?.countryCallingCode}`;

export const getSelectInputProps = ({ isOpen, inputProps }) => ({
  pl: 2,
  ...(!isOpen && { maxWidth: 0, pl: 0 }),
  ...(isOpen && { maxWidth: 30 }),
  ...inputProps
});

export const getNewCountry = (inputValue, countries) => {
  const trimmedValue = inputValue.trim();
  let matchingCode = {};
  if (trimmedValue.startsWith('+')) {
    matchingCode = countries.find((item) => item.countryCallingCode === trimmedValue.slice(1, 4));
  }
  return matchingCode;
};

const format = ({ number, country }) => formatIncompletePhoneNumber(number, country).trim();

export const parse = (params) => {
  const { number, country, countryCallingCode } = params;
  if (!number) {
    return { formatted: '', ...(country && { country }), ...(countryCallingCode && { countryCallingCode }) };
  }
  const fullNumber = number.includes('+') ? number : `+${countryCallingCode}${number}`;
  if (isValidPhoneNumber(fullNumber)) {
    const parsed = parsePhoneNumber(fullNumber, country);
    return { ...parsed, formatted: format(parsed) };
  }
  return {
    number: parseIncompletePhoneNumber(fullNumber),
    formatted: format({ ...params, number: fullNumber }),
    ...(country && { country }),
    ...(countryCallingCode && { countryCallingCode })
  };
};
