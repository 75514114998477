// These are the current libraries that are supported, feel free to import any new ones to this file (comment the ones you don't need as each library increases build size), they obviously must be available in your node_modules or in your src folder
// https://react-icons.github.io/react-icons

export default {
  flags: () => import('country-flag-icons/react/3x2'),
  // ai: () => import('react-icons/ai'),
  // bi: () => import('react-icons/bi'),
  // di: () => import('react-icons/di'),
  // fi: () => import('react-icons/fi'),
  // fa: () => import('react-icons/fa'),
  // fc: () => import('react-icons/fc'),
  // gi: () => import('react-icons/gi'),
  // go: () => import('react-icons/go'),
  // gr: () => import('react-icons/gr'),
  // hi: () => import('react-icons/hi'),
  // im: () => import('react-icons/im'),
  // io: () => import('react-icons/io'),
  // io5: () => import('react-icons/io5'),
  md: () => import('react-icons/md')
  // ri: () => import('react-icons/ri'),
  // si: () => import('react-icons/si'),
  // ti: () => import('react-icons/ti'),
  // vsc: () => import('react-icons/vsc'),
  // wi: () => import('react-icons/wi')
};
