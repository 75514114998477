import { forwardRef } from 'react';
import { bool } from 'prop-types';

import Styled from './Loader.styled';

const Loader = forwardRef((props, ref) => <Styled.Loader ref={ref} {...props} />);

Loader.propTypes = {
  /** is loading */
  isLoading: bool
};

Loader.defaultProps = {
  isLoading: false
};

export default Loader;
