import styled from '@emotion/styled';
import { compose, space } from 'styled-system';

import { NoResult } from 'components/atoms';
import { elementSize } from 'components/helpers';

export default {
  Tags: styled.div`
    display: flex;
    align-items: center;
    ${compose(elementSize, space)}
  `,
  NoResult: styled(NoResult)`
    ${(p) => `
      display: flex;
      align-items: center;
      margin-bottom: ${p.theme.space[2]};
    `}
    ${elementSize(['height'])}
  `
};
