import { Children, useCallback, useEffect, useRef, useState } from 'react';
import { Global } from '@emotion/react';
import { any, array, bool, func, number, object, oneOfType, shape, string } from 'prop-types';
import { equals, includes, isNil, path, pipe, reduce, sortBy, zip } from 'ramda';

import { usePrevious } from 'hooks';

import { transformValue } from 'components/helpers';
import { Error } from 'components/atoms';

import Handle from './Handle/Handle';
import Styled, { globalStyles } from './Slider.styled';
import {
  endEvents,
  getHandleProps,
  getIndicatorLeft,
  getIndicatorRight,
  getNextValues,
  getStep,
  getValuePosition,
  moveEvents,
  validMin
} from './Slider.helpers';

const Slider = ({
  scale,
  value,
  defaultValue,
  range,
  minRange,
  disabled,
  elementSize,
  showLabels,
  getHandleLabel,
  indicatorOptions,
  onChange,
  error,
  ...props
}) => {
  const [handles, setHandles] = useState([]);
  const positionScale = useRef([]);
  const step = useRef(getStep(scale));
  const railRef = useRef();
  const [dragging, setDragging] = useState(null);
  const [focused, setFocused] = useState(null);
  const [outline, setOutline] = useState(true);
  const [selection, setSelection] = useState(transformValue('in', range, value !== undefined ? value : defaultValue));
  const prevValue = usePrevious(value, true);
  const prevSelection = usePrevious(selection, true);

  const change = useCallback(
    (changeVal) => {
      onChange(transformValue('out', range, changeVal));
      if (value === undefined) setSelection(changeVal);
    },
    [range, value, onChange]
  );

  useEffect(() => {
    if (!equals(value, prevValue)) setSelection(transformValue('in', range, value));
  }, [value, prevValue, range]);

  useEffect(() => {
    step.current = getStep(scale);
    positionScale.current = scale.map((_, index) => index * step.current);
  }, [scale, step]);

  useEffect(() => {
    const handlesPositions = selection.map((handle) => ({
      ...{ handle },
      ...getHandleProps(handle, scale, step.current)
    }));
    let diff = selection.find((item) => !includes(item, prevSelection));
    if (!diff) {
      diff = selection.find((item, index) => !equals(item, prevSelection[index]));
    }
    setHandles(handlesPositions);
    if (dragging && diff) {
      setDragging(diff);
      setFocused(diff);
    }
  }, [selection, range, step, scale, dragging, prevSelection]);

  const onDragStart = useCallback(
    (index) => {
      let handlePosition = handles[index];
      setDragging(handlePosition.handle);
      setFocused(handlePosition.handle);
      setOutline(false);

      const onDrag = (event) => {
        const clientX = event?.clientX || event?.touches?.[0]?.clientX || 0;
        const { left, width } = railRef.current.getBoundingClientRect();
        const x = (Math.min(Math.max(parseInt(clientX - left, 10), 0), width) / width) * 100;

        const closestValue = pipe(
          zip,
          reduce((prev, curr) => (Math.abs(curr[1] - x) < Math.abs(prev[1] - x) ? curr : prev), [null, -100]),
          path([0])
        )(scale, positionScale.current);

        if (handlePosition !== closestValue) {
          const newValues = sortBy(
            (val) => getValuePosition(val, scale),
            Object.assign([...handles.map((item) => item.handle)], { [index]: closestValue })
          );
          if (!range || validMin(newValues, scale, minRange)) {
            change(newValues);
          }
        }
        handlePosition = closestValue;
      };

      const onDragEnd = () => {
        moveEvents.map((eventName) => document.removeEventListener(eventName, onDrag, false));
        endEvents.map((eventName) => document.removeEventListener(eventName, onDragEnd, false));
        setDragging(null);
      };

      moveEvents.map((eventName) => document.addEventListener(eventName, onDrag, false));
      endEvents.map((eventName) => document.addEventListener(eventName, onDragEnd, false));
    },
    [railRef, range, change, minRange, handles, scale, positionScale]
  );

  const onKeyDown = useCallback(
    (event, index) => {
      setOutline(true);
      const valuePos = getValuePosition(handles[index].handle, scale);
      let iterator = 0;
      if (event.key === 'ArrowRight') iterator = 1;
      if (event.key === 'ArrowLeft') iterator = -1;
      if (iterator !== 0) {
        const newValues = getNextValues({ valuePos, iterator, handles, scale, minRange, index });
        if (newValues) {
          setFocused(newValues.selected);
          change(newValues.values);
        }
      }
    },
    [minRange, handles, scale, change]
  );

  return (
    <Styled.Slider elementSize={elementSize} {...props}>
      {!isNil(dragging) && <Global styles={globalStyles} />}
      <Styled.Rail ref={railRef} elementSize={elementSize}>
        <Styled.Indicator
          error={error}
          left={getIndicatorLeft(handles, indicatorOptions)}
          right={getIndicatorRight(handles, indicatorOptions)}
        />
        {Children.toArray(
          handles.map((item, index) => (
            <Handle
              dragging={dragging}
              error={error}
              focused={focused}
              value={item.handle}
              elementSize={elementSize}
              disabled={disabled}
              outline={outline}
              left={item.left}
              onMouseDown={() => onDragStart(index)}
              onTouchStart={() => onDragStart(index)}
              onKeyDown={(e) => onKeyDown(e, index)}>
              {showLabels && getHandleLabel(item.handle)}
            </Handle>
          ))
        )}
      </Styled.Rail>
      <Error>{typeof error === 'string' && error}</Error>
    </Styled.Slider>
  );
};

Slider.propTypes = {
  /** on value change */
  onChange: func,
  /** minimum space between handles */
  minRange: number,
  /** is range */
  range: bool,
  /** array of options */
  scale: array,
  /** selected option or array of options if multiple, use if controlled */
  value: oneOfType([any, array]),
  /** default selected option/options, use if uncontrolled */
  defaultValue: oneOfType([any, array]),
  /** is disabled */
  disabled: bool,
  /** custom styled-system prop based on theme.elementSizes */
  elementSize: oneOfType([number, string, array, object]),
  /** show handle label */
  showLabels: bool,
  /** used to determine the string value for a given handle  */
  getHandleLabel: func,
  /** field error, only shows red border if true */
  error: oneOfType([bool, string]),
  /** indicator options, to be used when there's a single handle */
  indicatorOptions: shape({
    fromMin: bool,
    toMax: bool
  })
};

Slider.defaultProps = {
  onChange: () => null,
  minRange: 0,
  range: false,
  scale: [],
  value: undefined,
  defaultValue: undefined,
  disabled: false,
  elementSize: 0,
  showLabels: false,
  getHandleLabel: (handle) => handle?.label,
  error: null,
  indicatorOptions: { fromMin: true, toMax: false }
};

export default Slider;
