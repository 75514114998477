import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';

import { Box, Card, Text } from 'components/atoms';

import spinner from 'assets/lotties/spinner.json';

const LoadingCard = () => {
  const { t } = useTranslation('general');
  return (
    <Card display="flex" flexDirection="column" width="336px" minHeight="209px" borderRadius={2} bg="light" m="auto">
      <Box display="flex" flex={1}>
        <Text textAlign="center" color="dark" fontSize={16} m="auto">
          {`${t('general:loading')}`}
        </Text>
      </Box>
      <Box display="flex" flex={1} alignItems="center" width={1 / 2} m="auto">
        <Lottie animationData={spinner} loop autoplay width="150px" height="84px" />
      </Box>
    </Card>
  );
};

export default LoadingCard;
