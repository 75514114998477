import { useCallback, useEffect, useState } from 'react';
import { func, number, string } from 'prop-types';
import { useId } from 'react-id-generator';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { or } from 'ramda';

import { Box, Button, Icon, Row } from 'components/atoms';

import Field from '../Field/Field';

const Pagination = ({ page, pages, onChange, limit, id }) => {
  const defaultId = useId(1, 'pagination')[0];
  const finalId = or(id, defaultId);
  const theme = useTheme();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(page);

  const setPage = useCallback(
    (selectedPage) => {
      if (selectedPage !== page) onChange({ page: selectedPage, limit });
    },
    [page, limit, onChange]
  );

  useEffect(() => {
    setInputValue(page);
  }, [page]);

  return (
    <Row justifyContent="center" alignItems="center" gutter="1">
      <Box px="1">
        <Button
          id={`${finalId}_previous`}
          variant="secondary"
          iconOnly
          bare
          disabled={page <= 1}
          onClick={() => setPage(page - 1)}>
          <Icon name="MdChevronLeft" size={20} />
        </Button>
      </Box>
      <Box px="1">
        <Field
          id={`${finalId}_search`}
          mb="0"
          type="number"
          inputProps={{
            textAlign: 'center',
            width: `calc(${pages.toString().length + 1}ch + ${theme.space[2]} * 2)`
          }}
          value={inputValue}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              setPage(inputValue);
            }
          }}
          onValueChange={(value) => {
            let val = page;
            if (value === '') {
              setInputValue('');
            }
            if (!/^\d+$/.test(value)) {
              return;
            }
            val = parseInt(value, 10);
            if (val > 0 && val <= pages) {
              setInputValue(val);
            }
          }}
          onBlur={() => {
            setInputValue(page);
          }}
        />
      </Box>
      <Box px="1">
        {t('paginationOf')} {pages}
      </Box>
      <Box px="1">
        <Button
          id={`${finalId}_next`}
          variant="secondary"
          iconOnly
          bare
          disabled={page >= pages}
          onClick={() => setPage(page + 1)}>
          <Icon name="MdChevronRight" size={20} />
        </Button>
      </Box>
    </Row>
  );
};

Pagination.propTypes = {
  /** current page */
  page: number.isRequired,
  /** total number of pages */
  pages: number.isRequired,
  /** on page change */
  onChange: func.isRequired,
  /** limit per page (returned on page change) */
  limit: number,
  /** id string, will be used to identify the input and the buttons */
  id: string
};

Pagination.defaultProps = {
  limit: 10,
  id: null
};

export default Pagination;
