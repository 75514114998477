import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { Box } from 'components/atoms';

const loadingAnimation = keyframes`
  0% { opacity: 1; height: 84px }
  100% { opacity: 0; height: 0px }
`;

const thumbsUpAnimation = keyframes`
  0% { opacity: 0; height: 0px }
  100% { opacity: 1; height: 84px;  }
`;

export default {
  FadeOut: styled(Box)`
    position: initial;
    top: -84px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 150px;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    ${(p) =>
      p.shouldTransition &&
      css`
        animation: ${css`
          ${loadingAnimation} 0.3s linear 0s forwards;
        `};
      `}
  `,
  FadeIn: styled(Box)`
    position: initial;
    opacity: 0;
    top: -84px;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(p) =>
      p.shouldTransition &&
      css`
        animation: ${css`
          ${thumbsUpAnimation} 0.3s linear 0s forwards;
        `};
      `}
  `
};
