import { Children, useEffect, useState } from 'react';
import { any, array, func, number, object, oneOfType, string } from 'prop-types';
import { AsLink } from 'components/atoms';
import { matchPath, useLocation } from 'react-router-dom';
import { usePrevious } from 'hooks';
import { equals } from 'ramda';
import Tab from './Tab/Tab';
import Styled from './Tabs.styled';

const Tabs = ({ states, renderTab, defaultValue, value, onChange, getTabDisabled, getTabLabel, ...props }) => {
  const [innerValue, setInnerValue] = useState(value !== undefined ? value : defaultValue || states[0]);
  const prevValue = usePrevious(value, true);
  const location = useLocation();

  useEffect(() => {
    if (value !== prevValue) setInnerValue(value);
  }, [value, prevValue]);

  return (
    <Styled.Container {...props}>
      <Styled.List>
        {Children.toArray(
          states.map((tabProps) => {
            const { to, state } = tabProps;
            const tabLabel = getTabLabel(tabProps);
            return (
              <li>
                {renderTab({
                  ...tabProps,
                  ...(to && {
                    as: AsLink,
                    ...(state && {
                      to: { pathname: to, state }
                    })
                  }),
                  type: 'button',
                  onClick: () => {
                    onChange(tabProps);
                    if (value === undefined) setInnerValue(tabProps);
                  },
                  disabled: getTabDisabled(tabProps),
                  selected: to ? !!matchPath(to, location.pathname) : equals(innerValue, tabProps),
                  title: tabLabel,
                  children: tabLabel
                })}
              </li>
            );
          })
        )}
      </Styled.List>
    </Styled.Container>
  );
};

Tabs.Tab = Tab;

Tabs.propTypes = {
  /** list of tabs parameters, use Link 'to' prop from react-router-dom to match a route (strict and exact props will also be used for the match) or just pass in any object you want (and control the tabs with value, defaultValue and onChange) */
  states: array,
  /** selected tab, use if controlled */
  value: any,
  /** default selected tab, use if uncontrolled */
  defaultValue: any,
  /** on tab change */
  onChange: func,
  /** is tab disabled */
  getTabDisabled: func,
  /** get each tab's label */
  getTabLabel: func,
  /** custom tab element (default Tab can be accessed via Tabs.Tab) */
  renderTab: func,
  /** styled-system width prop */
  width: oneOfType([number, string, array, object])
};

Tabs.defaultProps = {
  states: [],
  defaultValue: null,
  value: undefined,
  onChange: () => null,
  getTabDisabled: (tab) => tab?.disabled,
  getTabLabel: (tab) => tab?.label,
  renderTab: (props) => <Tab {...props} />,
  width: '100%'
};

export default Tabs;
