import { Children, useCallback, useEffect, useState } from 'react';
import { array, bool, func, number, object, oneOfType, string } from 'prop-types';
import { useId } from 'react-id-generator';
import { equals, or } from 'ramda';
import { useTranslation } from 'react-i18next';

import { usePrevious } from 'hooks';

import { Button, Icon, Row, Tag, Text } from 'components/atoms';

import { isSelected, toggleValue } from 'components/helpers';
import Styled from './Tags.styled';
import getValue from './Tags.helpers';

const Tags = ({
  id,
  tagsList,
  value,
  defaultValue,
  readOnly,
  onChange,
  onValueChange,
  getTagLabel,
  getTagSelected,
  noTagMessage,
  isLoading,
  elementSize,
  renderTag,
  gutter,
  ...props
}) => {
  const { t } = useTranslation();
  const defaultId = useId(1, 'tags')[0];
  const [selection, setSelection] = useState(value || defaultValue);
  const prevValue = usePrevious(value, true);

  const change = useCallback(
    (tags) => {
      onChange(tags);
      onValueChange(tags.value);
      if (value === undefined) setSelection(tags.value);
    },
    [value, onChange, onValueChange]
  );

  useEffect(() => {
    if (!equals(value, prevValue)) setSelection(value);
  }, [value, prevValue]);

  return (
    <Styled.Tags aria-hidden {...props} elementSize={elementSize}>
      {(!!tagsList?.length || !!selection?.length) && (
        <Row gutter={gutter}>
          {Children.toArray(
            (tagsList || selection).map((tag, index) =>
              renderTag({
                elementSize,
                tag,
                index,
                tags: selection,
                change,
                mx: 1,
                mb: 2,
                selected: tagsList ? isSelected(tag, selection, getTagSelected) : true,
                ...(!readOnly &&
                  tagsList && {
                    cursor: 'pointer',
                    onClick: (event) => {
                      event.preventDefault();
                      change({ value: toggleValue(tag, selection, getTagSelected), tag, index });
                    }
                  }),
                ...(!readOnly && !tagsList && { pr: 0 }),
                children: (
                  <>
                    <Text as="span" ellipsis title={getTagLabel(tag)}>
                      {getTagLabel(tag)}
                    </Text>
                    {!readOnly && !tagsList && (
                      <Button
                        id={`${or(id, defaultId)}_tag_delete-${index}`}
                        type="button"
                        iconOnly
                        borderTopLeftRadius="0"
                        borderBottomLeftRadius="0"
                        elementSize={elementSize}
                        aria-label="Delete"
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          change({ value: getValue(selection, tag), tag, index });
                        }}>
                        <Icon name="MdClose" />
                      </Button>
                    )}
                  </>
                )
              })
            )
          )}
        </Row>
      )}
      <Styled.NoResult isLoading={isLoading} elementSize={elementSize}>
        {noTagMessage || t('noSelection')}
      </Styled.NoResult>
    </Styled.Tags>
  );
};

Tags.propTypes = {
  /** prefix the id of each tag delete button, the string rendered will be id_tag_delete-index */
  id: string,
  /** list of selectable tags, if null, tags added via defaultValue/value will have a delete button, as tags shown will reflect the value of the component */
  tagsList: array,
  /** list of selected tags, use if controlled */
  value: array,
  /** default list of selected tags, use if uncontrolled */
  defaultValue: array,
  /** is readonly */
  readOnly: bool,
  /** on selection change, receives an object containing the new value, the tag that was changed and its index */
  onChange: func,
  /** is only passed the value */
  onValueChange: func,
  /** message to display when no tags */
  noTagMessage: string,
  /** is loading */
  isLoading: bool,
  /** custom styled-system prop based on theme.elementSizes */
  elementSize: oneOfType([number, string, array, object]),
  /** custom tag element */
  renderTag: func,
  /** tags row gutter */
  gutter: oneOfType([number, string, array, object]),
  /** used to determine the string value for a given tag.  */
  getTagLabel: func,
  /** used to determine if a tag (specified via tagsList) is selected */
  getTagSelected: func
};

Tags.defaultProps = {
  id: null,
  tagsList: null,
  value: undefined,
  defaultValue: [],
  readOnly: false,
  onChange: () => null,
  onValueChange: () => null,
  noTagMessage: null,
  isLoading: false,
  elementSize: 0,
  renderTag: (props) => <Tag {...props} />,
  gutter: 1,
  getTagLabel: (tag) => tag?.label,
  getTagSelected: (tag, value) => equals(tag, value)
};

export default Tags;
