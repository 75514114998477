import styled from '@emotion/styled';
import { isNil } from 'ramda';
import { color, compose, get, system } from 'styled-system';

export const getVariant = (focus, readOnly) => (focus && !readOnly ? 'primary' : 'secondary');

export default {
  Accessory: styled.span`
    ${(p) => `
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 ${p.theme.space[1]};
      border: none;
      position: relative;
    `}
    &:hover,
    &:focus {
      ${system({
        hoverColor: {
          scale: 'colors',
          property: 'color'
        }
      })}
    }
    ${(p) => p.pullRight && `margin-right: -${p.theme.space[1]};`}
    ${(p) =>
      system({
        elementSize: {
          scale: 'elementSizes',
          property: 'minWidth',
          transform: (val, scale) => !isNil(val) && `calc(${get(scale, val)} - ${p.theme.borderWidths[1]} * 2)`
        }
      })}
    ${compose(color, system({ cursor: true }))}
  `,
  Content: styled.span`
    position: absolute;
    left: 40%;
    top: 50%;
    transform: translate(-50%, -50%);
  `
};
