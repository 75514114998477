import 'react-toastify/dist/ReactToastify.css';

import { Box, Loader } from 'components/atoms';
import { TopBar } from 'components/organisms';
import Routes from 'Routes';

const App = () => (
  <div>
    <Loader />
    <Box display="flex" flexDirection="column" height="100vh">
      <TopBar />
      <Box display="flex" flex={1} bg="light">
        <Routes />
      </Box>
    </Box>
  </div>
);

export default App;
