import { is } from 'ramda';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';

import { Box, Card, Picture, Text } from 'components/atoms';
import errorIcon from 'assets/cloud_offline.svg';
import spinner from 'assets/lotties/spinner.json';
import Styled from './Error.styled';

const Error = () => {
  const { t, i18n } = useTranslation('errors');
  const { code } = useParams();

  return (
    <Card display="flex" flexDirection="column" width="336px" minHeight="209px" borderRadius={2} bg="light" m="auto">
      <Box display="flex" flex={1}>
        <Text color="dark" fontSize={16} textAlign="center" m="auto">
          {t(`errors:${is(String, code) && i18n.exists(`errors:${code}`) && code !== 'defaut' ? code : 'default'}`)}
        </Text>
      </Box>
      <Box display="flex" flex={1} alignItems="center" justifyContent="center" position="relative" minHeight="84px">
        <Styled.FadeOut>
          <Lottie animationData={spinner} loop autoplay />
        </Styled.FadeOut>
        <Styled.FadeIn>
          <Picture src={errorIcon} alt="" />
        </Styled.FadeIn>
      </Box>
    </Card>
  );
};

export default Error;
