import { ellipsis } from 'polished';
import { equals } from 'ramda';
import styled from '@emotion/styled';

import { elementSize } from 'components/helpers';

export default {
  Handle: styled.span`
    ${(p) => `
      position: absolute;
      top: 50%;
      left: ${p.left}%;
      transform: translate(-50%, -50%);
    `}
    ${(p) => p.focus && 'z-index: 20;'}
  `,
  Button: styled.button`
    ${(p) => `
      display: block;
      padding: 0;
      outline: none;
      border-radius: 50%;
      border: ${p.theme.borderWidths[1]} solid ${p.error ? p.theme.colors.red : p.theme.colors.primary};
    `}
    ${(p) =>
      p.outline &&
      `
        &:focus {
          background: ${p.error ? p.theme.colors.red : p.theme.colors.primary};
        }
    `}
    ${(p) =>
      equals(p.dragging, p.value)
        ? `
          cursor: grabbing;
          background: ${p.error ? p.theme.colors.red : p.theme.colors.primary};
        `
        : `
          background: ${p.theme.colors.light};
        `}
    ${(p) =>
      !p.dragging &&
      `
        cursor: grab;
        &:hover { 
          border-color: ${p.error ? p.theme.colors.red : p.theme.colors.primary};
        }
    `}
    ${elementSize(['height', 'width'])}
  `,
  Text: styled.span`
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -100%);
    ${ellipsis(80)}
  `
};
