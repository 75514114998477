/* eslint-disable camelcase */
import { REGEXES } from 'utils/constants';

export default (t) => ({
  contractor: {
    required: `${t('validation:mandatory')}`
  },
  spoc: {
    required: `${t('validation:mandatory')}`,
    pattern: {
      value: REGEXES.NAME,
      message: `${t('validation:invalid')}`
    }
  },
  spoc_email: {
    required: `${t('validation:mandatory')}`,
    pattern: {
      value: REGEXES.EMAIL,
      message: `${t('validation:invalid')}`
    },
    maxLength: {
      value: 255,
      message: `${t('validation:tooLong')} (255)`
    }
  },
  employee_name: {
    required: `${t('validation:mandatory')}`,
    pattern: {
      value: REGEXES.NAME,
      message: `${t('validation:invalid')}`
    },
    maxLength: {
      value: 32,
      message: `${t('validation:tooLong')} (32)`
    }
  },
  employee_surname: {
    required: `${t('validation:mandatory')}`,
    pattern: {
      value: REGEXES.NAME,
      message: `${t('validation:invalid')}`
    },
    maxLength: {
      value: 32,
      message: `${t('validation:tooLong')} (32)`
    }
  },
  employee_email: {
    required: `${t('validation:mandatory')}`,
    pattern: {
      value: REGEXES.EMAIL,
      message: `${t('validation:invalid')}`
    },
    maxLength: {
      value: 255,
      message: `${t('validation:tooLong')} (255)`
    }
  }
});
