import styled from '@emotion/styled';
import { get, system } from 'styled-system';
import { isNil } from 'ramda';

import { boxProps } from 'components/helpers';

export default {
  Row: styled.div`
    ${system({
      gutter: {
        scale: 'space',
        properties: ['marginLeft', 'marginRight'],
        transform: (val, scale) => !isNil(val) && `-${get(scale, val)}`
      }
    })}
    ${system({
      gutter: {
        scale: 'space',
        property: 'width',
        transform: (val, scale) => !isNil(val) && `calc(100% + ${get(scale, val)} * 2);`
      }
    })}
    ${boxProps}
  `
};
