import { forwardRef, useEffect, useState } from 'react';
import { array, func, number, object, oneOfType, string } from 'prop-types';
import { useTheme } from '@emotion/react';

import { usePrevious } from 'hooks';

import { Icon } from 'components/atoms';
import { getRadiusProps } from 'components/helpers';

import { hasError, noImg, noSrc } from './Picture.helpers';
import Styled, { getLoadingAnimation } from './Picture.styled';

const Picture = forwardRef(
  (
    { src, alt, onLoad, onError, objectFit, objectPosition, aspectRatio, renderIcon, iconSize, iconName, ...props },
    ref
  ) => {
    const theme = useTheme();
    const prevSrc = usePrevious(src, true);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(!!src);
    const displayProps = { src, error, isLoading };

    useEffect(() => {
      if (src !== prevSrc) {
        setError(false);
        setIsLoading(true);
      }
    }, [src, prevSrc]);

    return (
      <Styled.Picture ref={ref} {...props}>
        {noImg(displayProps) && (
          <Styled.Placeholder {...getRadiusProps(props)}>
            {hasError(displayProps) && alt}
            {(noSrc(displayProps) || isLoading) &&
              renderIcon({
                name: iconName,
                size: iconSize,
                color: 'grays.0',
                isLoading,
                ...(isLoading && { className: getLoadingAnimation(theme) })
              })}
          </Styled.Placeholder>
        )}
        <Styled.Img
          {...getRadiusProps(props)}
          objectFit={objectFit}
          objectPosition={objectPosition}
          aspectRatio={aspectRatio}
          alt={alt}
          onError={(e) => {
            setError(true);
            setIsLoading(false);
            onError(e);
          }}
          onLoad={(e) => {
            setIsLoading(false);
            onLoad(e);
          }}
          src={src || ''}
          noImg={noImg(displayProps)}
        />
      </Styled.Picture>
    );
  }
);

Picture.propTypes = {
  /** custom styled-system object-fit prop for img */
  objectFit: oneOfType([string, array, object]),
  /** custom styled-system object-position prop for img */
  objectPosition: oneOfType([string, array, object]),
  /** custom styled-system aspect-ratio prop for img */
  aspectRatio: oneOfType([string, array, object]),
  /** styled-system display prop  */
  display: oneOfType([string, array, object]),
  /** styled-system size prop for icon */
  iconSize: oneOfType([number, string, array, object]),
  /** icon name */
  iconName: string,
  /** img src */
  src: string,
  /** img alt, displayed if error */
  alt: string,
  /** img onError */
  onError: func,
  /** img onLoad */
  onLoad: func,
  /** placeholder icon element */
  renderIcon: func
};

Picture.defaultProps = {
  display: 'inline-block',
  objectFit: 'cover',
  objectPosition: 'center',
  aspectRatio: null,
  iconSize: 32,
  iconName: 'MdImage',
  src: null,
  alt: null,
  onError: () => null,
  onLoad: () => null,
  renderIcon: (props) => <Icon {...props} />
};

export default Picture;
