import { forwardRef } from 'react';
import { array, number, object, oneOfType, string } from 'prop-types';

import Box from '../Box/Box';

const Spinner = forwardRef((props, ref) => (
  <Box
    as="svg"
    ref={ref}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid">
    <circle cx="50" cy="50" r="13.3902" fill="none" stroke="currentColor" strokeWidth="10">
      <animate
        attributeName="r"
        repeatCount="indefinite"
        dur="1s"
        values="0;40"
        keyTimes="0;1"
        keySplines="0 0.2 0.8 1"
        calcMode="spline"
        begin="-0.5s"
      />
      <animate
        attributeName="opacity"
        repeatCount="indefinite"
        dur="1s"
        values="1;0"
        keyTimes="0;1"
        keySplines="0.2 0 0.8 1"
        calcMode="spline"
        begin="-0.5s"
      />
    </circle>
    <circle cx="50" cy="50" r="33.1222" fill="none" stroke="currentColor" strokeWidth="10">
      <animate
        attributeName="r"
        repeatCount="indefinite"
        dur="1s"
        values="0;40"
        keyTimes="0;1"
        keySplines="0 0.2 0.8 1"
        calcMode="spline"
      />
      <animate
        attributeName="opacity"
        repeatCount="indefinite"
        dur="1s"
        values="1;0"
        keyTimes="0;1"
        keySplines="0.2 0 0.8 1"
        calcMode="spline"
      />
    </circle>
  </Box>
));

Spinner.propTypes = {
  /** styled-system size prop */
  size: oneOfType([number, string, array, object]),
  /** styled-system color prop */
  color: oneOfType([string, array, object]),
  /** styled-system vertical-align prop */
  verticalAlign: oneOfType([string, array, object])
};

Spinner.defaultProps = {
  size: '1.5em',
  color: 'primary',
  verticalAlign: 'middle'
};

export default Spinner;
