import { useCallback } from 'react';
import { array, bool, func, number, object, oneOfType, string } from 'prop-types';
import { useTheme } from '@emotion/react';

import { Icon, Text } from 'components/atoms';

import Styled, { getVariant } from './Accessory.styled';
import DefaultAccessory from './DefaultAccessory';

const Accessory = ({
  focus,
  disabled,
  icon,
  size,
  textIcon,
  library,
  elementSize,
  renderElement,
  onClick,
  readOnly,
  ...restProps
}) => {
  const theme = useTheme();
  const render = useCallback(
    (props) => {
      if (typeof renderElement === 'function') return renderElement(props);
      return <DefaultAccessory {...props} />;
    },
    [renderElement]
  );
  const buttonProps = {
    variant: getVariant(focus, readOnly),
    focus,
    disabled: disabled || readOnly,
    icon,
    textIcon,
    size,
    library,
    elementSize,
    minHeight: '100%',
    height: '100%',
    borderRadius: 0,
    mr: `-${theme.borderWidths[1]}`,
    ...((disabled || readOnly) && { bare: true }),
    ...restProps
  };
  const defaultAccessoryProps = {
    readOnly,
    elementSize,
    onClick,
    ...(onClick ? { as: 'button', type: 'button', cursor: 'pointer' } : { 'aria-hidden': true }),
    ...(icon && {
      children: (
        <Styled.Content>
          <Icon name={icon} library={library} size={size} pointerEvents="none" />
        </Styled.Content>
      )
    }),
    ...(textIcon && {
      children: (
        <Text fontSize="4" fontWeight="1">
          {textIcon}
        </Text>
      )
    }),
    ...restProps
  };

  return render({
    ...buttonProps,
    defaultAccessoryProps
  });
};

Accessory.propTypes = {
  /** is input focused */
  focus: bool,
  /** icon name */
  icon: string,
  /** icon size */
  size: oneOfType([number, string, array, object]),
  /** library name */
  library: string,
  /** text instead of icon */
  textIcon: string,
  /** styled-system color prop */
  color: oneOfType([string, array, object]),
  /** custom styled-system hover and focus color */
  hoverColor: oneOfType([string, array, object]),
  /** styled-system bg prop */
  bg: oneOfType([string, array, object]),
  /** custom styled-system prop based on theme.elementSizes */
  elementSize: oneOfType([number, string, array, object]),
  /** onClick */
  onClick: func,
  /** is readonly */
  readOnly: bool,
  /** is disabled */
  disabled: bool,
  /** custom element to replace container */
  renderElement: func
};

Accessory.defaultProps = {
  focus: false,
  icon: null,
  size: 20,
  library: 'md',
  textIcon: null,
  color: 'inherit',
  hoverColor: 'inherit',
  bg: 'transparent',
  elementSize: 1,
  onClick: null,
  readOnly: false,
  disabled: false,
  renderElement: null
};

export default Accessory;
