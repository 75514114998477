import { css } from '@emotion/react';
import normalize from 'normalize';

import theme from 'theme';

export default css`
  ${normalize}

  body {
    font-family: ${theme.font};
    font-size: ${theme.fontSizes[2]};
    line-height: ${theme.lineHeight};
    color: ${theme.colors.dark};
    background: ${theme.colors.grays[3]};
  }

  *:focus {
    /* please provide a way to know each element (that can take focus: button, a, input, el with tabindex) is focused */
    outline: none;
  }

  ::placeholder {
    opacity: 1;
    color: ${theme.colors.grays[0]};
  }

  input {
    -webkit-appearance: none;
  }

  input::-webkit-clear-button,
  input::-webkit-search-cancel-button {
    display: none;
  }

  ::-ms-clear {
    display: none;
  }

  figure {
    margin: 0;
  }
`;
