import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { position } from 'styled-system';

const loadingAnimation = keyframes`
  0% { width: 0; }
  100% { width: 95%; }
`;

const loadedAnimation = keyframes`
  0% { width: 100%; }
  100% { width: 100%; opacity: 0; }
`;

export default {
  Loader: styled.div`
    ${(p) => `
      position: fixed;
      top: 0;
      left: 0;
      width: 0;
      height: ${p.theme.borderWidths[1]};
      background: ${p.theme.colors.primary};
      z-index: 80;
    `}
    ${(p) => css`
      animation: ${p.isLoading
        ? css`
            ${loadingAnimation} 5s linear forwards;
          `
        : css`
            ${loadedAnimation} ${p.theme.transition} forwards;
          `};
    `}
    ${position}
  `
};
